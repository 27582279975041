import React, { useState } from "react";
import Layout from "../../../Layout/index.jsx";
import CategoryPageBannerSection from "../../../global/categoryPageBannerSection/categoryPageBannerSection.jsx";
import CategoryTitleSection from "../../../global/categoryPageBannerSection/categoryTitleSection.jsx";
import "../../Home/homestyle.css";
import ClientSection from "../../../global/clients_section/client_section.jsx";
import ContactSection from "../../../global/contactUsSection/ContactSection.jsx";
import "../services.css";
import reelsImg from "../../../assets/images/reels/reelsBanner.jpeg";

import box from "../../../assets/videos/reels/box.jpeg";
import airShow from "../../../assets/videos/reels/airShow.jpeg";
import book from "../../../assets/videos/reels/book.jpeg";
import cafe from "../../../assets/videos/reels/cafe.jpeg";
import edge from "../../../assets/videos/reels/edge.jpeg";
import game from "../../../assets/videos/reels/game.jpeg";
import gulfood from "../../../assets/videos/reels/gulfood.jpeg";
import shake from "../../../assets/videos/reels/shake.jpeg";
import pizza2 from "../../../assets/videos/reels/pizza2.jpeg";
import pizzaMaking from "../../../assets/videos/foodVideos/pizzaMaking.jpeg";
import coffee from "../../../assets/videos/reels/coffee.jpeg";

import Video1 from "../../../assets/videos/reels/6.mp4";
import airShowVideo from "../../../assets/videos/reels/Air Show Highlight V2 MBL Instagram.mp4";
import edgeVideo from "../../../assets/videos/reels/EDGE Highlight V1 MBL Instagram.mp4";
import gulfoodVideo from "../../../assets/videos/reels/Gulf Food Instagram.mp4";
import pizzaMakingVideo from "../../../assets/videos/reels/Pizza Reel V1 MBL.mp4";
import pizza2Video from "../../../assets/videos/reels/Pizza Reel V3 MBL.mp4";
import bookVideo from "../../../assets/videos/reels/Therapy Reel Book V2 MBL.mp4";
import shakeVideo from "../../../assets/videos/reels/Therapy Reel Cold Bevarege V1 MBL.mp4";
import coffeeVideo from "../../../assets/videos/reels/Therapy Reel Hot beverage V4 MBL.mp4";
import gameVideo from "../../../assets/videos/reels/Therapy Reel Play Station V5 MBL.mp4";
import cafeVideo from "../../../assets/videos/reels/Therapy Reel V2 MBL.mp4";
import "../../Home/homestyle.css";

const Reels = () => {
  const [playVideo, setPlayVideo] = useState(false);
  const togglePlayVideo = () => {
    setPlayVideo(!playVideo);
  };
  const [playVideo1, setPlayVideo1] = useState(false);
  const togglePlayVideo1 = () => {
    setPlayVideo1(!playVideo1);
  };
  const [playVideo2, setPlayVideo2] = useState(false);
  const togglePlayVideo2 = () => {
    setPlayVideo2(!playVideo2);
  };
  const [playVideo3, setPlayVideo3] = useState(false);
  const togglePlayVideo3 = () => {
    setPlayVideo3(!playVideo3);
  };
  const [playVideo4, setPlayVideo4] = useState(false);
  const togglePlayVideo4 = () => {
    setPlayVideo4(!playVideo4);
  };
  const [playVideo5, setPlayVideo5] = useState(false);
  const togglePlayVideo5 = () => {
    setPlayVideo5(!playVideo5);
  };
  const [playVideo6, setPlayVideo6] = useState(false);
  const togglePlayVideo6 = () => {
    setPlayVideo6(!playVideo6);
  };
  const [playVideo7, setPlayVideo7] = useState(false);
  const togglePlayVideo7 = () => {
    setPlayVideo7(!playVideo7);
  };
  const [playVideo8, setPlayVideo8] = useState(false);
  const togglePlayVideo8 = () => {
    setPlayVideo8(!playVideo8);
  };
  const [playVideo9, setPlayVideo9] = useState(false);
  const togglePlayVideo9 = () => {
    setPlayVideo9(!playVideo9);
  };
  const [playVideo10, setPlayVideo10] = useState(false);
  const togglePlayVideo10 = () => {
    setPlayVideo10(!playVideo10);
  };
  return (
    <>
      <Layout>
        <CategoryPageBannerSection
          bannerImage={reelsImg}
        ></CategoryPageBannerSection>
        <CategoryTitleSection catTitle="Reels Videography"></CategoryTitleSection>
        <section className="video_section">
          <div className="page_width">
            <div className="video_section_content">
              <div className="video1">
                <button className="video_button" onClick={togglePlayVideo}>
                  <img src={box} alt="Reels Videography Services in Dubai" />
                </button>
                {playVideo && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo}></div>
                    <div className="video_content1">
                      <video
                        src={Video1}
                        width="100%"
                        controls
                        autoplay
                        muted
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="video2">
                <button className="video_button" onClick={togglePlayVideo1}>
                  <img src={airShow} alt="Air Show Highlight" />
                </button>
                {playVideo1 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo1}></div>
                    <div className="video_content1">
                      <video
                        src={airShowVideo}
                        width="100%"
                        controls
                        autoplay
                        muted
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="video3">
                <button className="video_button" onClick={togglePlayVideo2}>
                  <img src={book} alt="Therapy Reel Book V2" />
                </button>
                {playVideo2 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo2}></div>
                    <div className="video_content1">
                      <video
                        src={bookVideo}
                        width="100%"
                        controls
                        autoplay
                        muted
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="video4">
                <button className="video_button" onClick={togglePlayVideo3}>
                  <img src={cafe} alt="Therapy Board Game Cafe" />
                </button>
                {playVideo3 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo3}></div>
                    <div className="video_content1">
                      <video
                        src={cafeVideo}
                        width="100%"
                        controls
                        autoplay
                        muted
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="video5">
                <button className="video_button" onClick={togglePlayVideo4}>
                  <img src={edge} alt="Edge Reels" />
                </button>
                {playVideo4 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo4}></div>
                    <div className="video_content1">
                      <video
                        src={edgeVideo}
                        width="100%"
                        controls
                        autoplay
                        muted
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="video6">
                <button className="video_button" onClick={togglePlayVideo5}>
                  <img src={game} alt="Therapy Reel Play Station V5 MBL" />
                </button>
                {playVideo5 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo5}></div>
                    <div className="video_content1">
                      <video
                        src={gameVideo}
                        width="100%"
                        controls
                        autoplay
                        muted
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="video7">
                <button className="video_button" onClick={togglePlayVideo6}>
                  <img src={gulfood} alt="GulFood Reels Shoot" />
                </button>
                {playVideo6 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo6}></div>
                    <div className="video_content1">
                      <video
                        src={gulfoodVideo}
                        width="100%"
                        controls
                        autoplay
                        muted
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="video8">
                <button className="video_button" onClick={togglePlayVideo7}>
                  <img src={shake} alt="Therapy Reel Cold Bevarege" />
                </button>
                {playVideo7 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo7}></div>
                    <div className="video_content1">
                      <video
                        src={shakeVideo}
                        width="100%"
                        controls
                        autoplay
                        muted
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="video9">
                <button className="video_button" onClick={togglePlayVideo8}>
                  <img src={pizza2} alt="Pizza Reels Shhot in Dubai" />
                </button>
                {playVideo8 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo8}></div>
                    <div className="video_content1">
                      <video
                        src={pizza2Video}
                        width="100%"
                        controls
                        autoplay
                        muted
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="video10">
                <button className="video_button" onClick={togglePlayVideo9}>
                  <img src={pizzaMaking} alt="Pizza Making Shoot in Dubai" />
                </button>
                {playVideo9 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo9}></div>
                    <div className="video_content1">
                      <video
                        src={pizzaMakingVideo}
                        width="100%"
                        controls
                        autoplay
                        muted
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="video11">
                <button className="video_button" onClick={togglePlayVideo10}>
                  <img
                    src={coffee}
                    alt="Therapy Reel Hot beverage Shoot in Dubai"
                  />
                </button>
                {playVideo10 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo10}></div>
                    <div className="video_content1">
                      <video
                        src={coffeeVideo}
                        width="100%"
                        controls
                        autoplay
                        muted
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <ContactSection></ContactSection>
        <section className="blog_section">
          <div className="page_width">
            <div className="blog_section_content">
              <h1>The Ultimate Guide to Reel Shoot Services in Dubai</h1>
              <p>
                With its impressive skyline views, luxurious life, and pulsating
                culture, Dubai has become one of the top creative cities. Amidst
                the trend, <span> Reel shoot services in Dubai </span> are
                becoming extremely popular. Whether you are a brand looking to
                make a statement on social media or an influencer keen on
                engaging your audience, professional{" "}
                <a href="/">
                  {" "}
                  <span> Reels videography in Dubai </span>
                </a>
                is truly magical. Several aspects of{" "}
                <span> Reels shoot services in Dubai </span> will be exposed
                with relevance to this blog on why it is necessary for your
                digital success.
              </p>
              <h2>Why Is Dubai the Perfect Venue for Reels Shoots?</h2>
              <p>
                From the calmness of the shores of Jumeirah to the busy streets
                of Downtown Dubai, there are scenes of glory that are the{" "}
                <span> best locations for reel shoots</span>. These diverse
                landscapes bring the perfect opportunity to make stunning and
                up-to-date Reels. Besides such opportunities, Dubai's
                cutting-edge technology and modern facilities will ensure that
                nothing but the highest quality reels are produced.
              </p>
              <h2>Professional Reels Videography in Dubai</h2>
              <p>
                Making an engaging Reel is not just a moment one has to click;
                it tells a story. When you are a professional in videography for
                Reels in Dubai, you get support from a team that works
                passionately on mastering the art of storytelling, lighting, and
                editing. Professionals ensure each second of their Reel is
                maximally done to bind your audience.
              </p>
              <h2>Key Services Offered For Reels Videography in Dubai</h2>
              <h3>Concept Development</h3>
              <p>
                Understand the brand and target audience to devise a unique
                concept.
              </p>
              <h3>Location Scouting</h3>
              <p>
                Locating the perfect places in Dubai that make your brief a
                reality in a Reel.
              </p>
              <h3>Shooting</h3>
              <p>
                High-end equipment to capture every moment beautifully. Editing:
                Professional editing techniques to weave a flawless narrative.
                Post-production: Colour grading, sound design, special
                effects—giving it the final touch.
              </p>
              <h2>Why Opt for Reels Videography Services in Dubai?</h2>
              <p>
                High production values are part of the reason Dubai reels
                shooting services are some renowned companies. Professionals
                will make sure your Reels look amazing and are professionally
                edited, making them outstanding at social marketing.
              </p>
              <h3>Time-consuming</h3>
              <p>
                The creation of a Reel usually goes through several stages, from
                planning, filming, editing, post-production. This will help save
                so much precious time for other aspects of your business.
              </p>
              <h3>Creative Expertise</h3>
              <p>
                A team full of creative brains will assist you in deriving some
                unique and fresh concepts and techniques, thus empowering you to
                bring forward the best of the best reels. The experts are
                constantly updating their knowledge from time to time due to the
                changing trends, hence your content is always updated.
              </p>
              <h3>Enhanced Engagement</h3>
              <p>
                Reels with good quality can enhance engagement on your social
                media manifold. Since professionally shot and edited reels are
                more likely to catch viewers' eyes, the likes, shares, and
                comments will be increased.
              </p>
              <h2>How to Choose the Right Reel Videography Service in Dubai</h2>
              <p>
                When choosing the best{" "}
                <span> Reels shoot service in Dubai </span>, consider the
                following:
              </p>
              <h3>Portfolio</h3>
              <p>Review past work in terms of style and quality.</p>
              <h3>Experience</h3>
              <p>
                Make sure they have experience in doing Reels with other brands
                similar to yours.
              </p>
              <h3>Equipment</h3>
              <p>
                Make sure it is indicated that they use the top of the line so
                that the final output video comes out clear in high definition.
              </p>
              <h3>Client Testimonials</h3>
              <p>
                Go through past client reviews and testimonials to get a sense
                of their satisfaction level.
              </p>
              <h2>Types of Reel Shoot Services in Dubai</h2>
              <h3>Brand Promotion Reels</h3>
              <p>
                Brand promotion Reels are used to showcase your products or
                services interactively and more attractively. These reels may be
                for new product launches, the company's behind-the-scenes
                activities, or customer testimonials all material is going to
                create a robust brand identity.
              </p>
              <h3> Event Covered Reels</h3>
              <p>
                Corporate events, product launches, and social gatherings are at
                the heart of the event coverage that Reels brings. For that
                memorable moment in your fan base and maintaining their loyalty,
                make the most of sharing momentums with your audience.
              </p>
              <h3>Travel and Lifestyle Reels</h3>
              <p>
                Think of Dubai, with magnificent landscapes and a luxurious
                lifestyle, as the coolest place for travel and lifestyle Reels.
                From stunning views at the top of the Burj Khalifa to an
                unforgettable night spent within the city, these Reels give
                viewers a feel of the best Dubai has in store.
              </p>
              <h3>Fashion and Beauty Reels</h3>
              <p>
                A city that's fashion-forward and where the beauty industry
                happens, fashion and beauty Reels could mean you represent the
                latest trends, styling tips, and beauty products.
              </p>
              <h3>Educational and Informative Reels</h3>
              <p>
                Educational Reels are perfect for sharing valuable information
                and insights with your target audience. Whether a quick
                tutorial, how-to guide, or industry tips, such Reels work like
                magic to establish you as an authority in your field.
              </p>
              <h2>Engaging Reels Videography in Dubai</h2>
              <h3>Know Your Audience</h3>
              <p>
                The knowledge of whom you are targeting is very key in trying to
                create Reels that will most engage them. Research what they are
                interested in, what they like, and how they behave to result in
                the right content.
              </p>
              <h3>Short and Sweet</h3>
              <p>
                Reels are short and sweet. It is paramount that you capture the
                attention of the audience within the first few seconds, though
                the visual content must be clear and straight to the point.
              </p>
              <h3>Trending Music and Effects</h3>
              <p>
                Relatable nature and shareable can be achieved by adding
                trending music and effects. Keep up with the trends.
              </p>
              <h3>Tell a Story</h3>
              <p>
                Every Reel you upload must contain some story, be it showing a
                product, sharing a moment, or delivering a message. Through a
                well-crafted storyline, your Reels will turn out more
                rememberable and persuasive.
              </p>
              <h3>Engage with Your Audience</h3>
              <p>
                Encourage your audience to interact with your Reels by posing
                questions, encouraging them to comment, and asking
                call-to-action questions/sentences at the end of the video. This
                positively influences the reach and visibility of your content.
              </p>
              <h2>Conclusion</h2>
              <p>
                <a
                  href="https://www.linkedin.com/company/zawiyafilms/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <span> Reel videography in Dubai </span>
                </a>
                is perfect for creativity, professionalism, and top-notch
                production. Well, help from the services permitted you to make
                engaging Reels that action might take the audience and enhance
                your online existence. If you truly wish to make a mark in
                social media, invest in professional Reels shoot services in
                Dubai. Welcome the power of narrative and take your digital
                influence to the next level!
              </p>
              <h2>Talk to Us</h2>
              <p>
                Get ready to cinch up your Reels to the next level as we assist
                in creating content that resonates with your audience. Lead on,
                and let our professional{" "}
                <span> Reel shoot services in Dubai </span> capture some
                exquisite backdrops.
              </p>
            </div>
          </div>
        </section>
        <ClientSection></ClientSection>
      </Layout>
    </>
  );
};

export default Reels;
