export const faqData = [
  {
    question: "What are the expenses?",
    answer:
      "The price varies from project to project because our services are customized to your needs.- The price of a corporate movie might range from 6000 to 8000 AED to 100,000 AED. The price in each instance includes all aspects of the production, such as storyboarding, voice-over and sound, filming, post-production, and scriptwriting. --Event Video Production-- Depending on the number of crew members and event days, event video production starts at 3000 to 5000 AED and upwards. --Commercial Video Production-- The cost of producing a commercial video varies depending on its subject matter and level of intricacy. The production of these videos may need a substantial budget, but we never compromise on quality for price, and we'll try to strike a balance between the two. --Testimonial Video Production-- Testimonial video production costs are determined based on the crew and length of filming, just like event video production costs. --Animated Video Production-- The cost of animated videos varies according to their complexity. &nbsp; Similar to commercial videos, the secret is balancing quality and affordability. --Line Production-- The cost of line production is dependent on numerous factors. Our ability to provide quality services within each client's budget is substantially enhanced by our affordable pricing, which stems from our in-house equipment. To provide complete transparency, itemized invoices are sent to clients.",
  },
  {
    question: "What steps are involved in our process?",
    answer:
      "We have a very simple method that applies to all services. We start by talking with you about your project, getting to know your organization and its needs, and finding out as much as we can about it. That's where we start for projects that need a script. After drawing out a storyboard and getting the audio ready, we begin filming or animating the film. After assembling all the components, we send you multiple drafts for your input. After making the required adjustments, we email you the completed draft for approval.",
  },
  {
    question: "What's the size of our film crew?",
    answer:
      "Film District Dubai has a significant workforce of industry professionals who are knowledgeable about various processes. We have enough talented filmmakers on staff to create videos in the studio, cover events from beginning to end, and give each customer and project the personalized attention they require.",
  },
  {
    question: "How much of your input do we need?",
    answer:
      "Since we think that effective communication is essential to fulfilling customer expectations, we will take the time to speak with you early on in the project to ensure that we have all the information we need. We don't need any help other than your feedback; after we get started producing your video, we'll send you drafts and progress reports for approval. Please allow us to drive while you devote your valuable time to growing your company.",
  },
  {
    question: "Which UAE locations do we service?",
    answer:
      "Abu Dhabi, Dubai, Sharjah, Ajman, Umm Al Quwain, Ras Al Khaimah, and Fujairah are among the Emirates that we service. We provide services to every community in Dubai. Al Buteen, Al Baraha, Al Dhagaya, Al Garhoud, Al Hamriya Port, Al Khabisi, Al Mamzar, Al Mizhar, Al Muraqqabat, Al Murar, Al Muteena, Al Nahda, Al Qusais, Al Ras, Al Rashidiya, Al Rigga, Al Sabkha, Al Twar, Al Waheda, Al Warqaa, Ayal Nasir, Dubai International Airport, Hor Al Anz, Mirdif, Muhaisnah, Nad Al Hammar, Nad Shamma, Naif, Port Saeed, Al Buteen, Umm Ramool, Warisan, Al Amardhi, Bur Dubai, Al Bada, Al Furjan, Al Hamriya, Al Hudaiba, Al Jaddaf, Al Jafilia, Al Karama, Al Kefaf, Al Manara, Al Mankhool, Al Markada, AlAl Wasl, Al Muntazah, Al Rifa, Al Satwa, Al Shindagha, Al Souk Al Kabir, Downtown Dubai, Dubai International City, Nad Al Sheba, and Old Metha, Al Barsha, Al Reem, Al Quoz, Al Safa, Al Sufouh, Dubai Marina, Emirates Hills, Jumeirah Islands, Port Rashid, Ras Al Khor, Ras Al Khor Industrial Area, Trade Centers 1 and 2, Um Hurair, Zabeel Towers at Jumeirah Lake Saheel Mudon, The Greens, Umm Al Sheif, Um Suqeim, Discovery Gardens of Jebel Ali, The Gardens at Dubai Investments Park, Jebel Ali Village.",
  },
];
