import React from "react";
import Layout from "../../Layout";
import PageBannerSection from "../../global/pageBannerSection/pageBannerSection";
import whyChooseUsImg from "../../assets/images/whyChooseUs.jpeg";
import "./whyChooseUs.css";
import "../Home/homestyle.css";
import { GrUserExpert } from "react-icons/gr";
import { FaLightbulb } from "react-icons/fa6";
import { MdVideoCameraFront } from "react-icons/md";
import { MdSendTimeExtension } from "react-icons/md";
import { FaPersonCircleCheck } from "react-icons/fa6";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const WhyChooseUs = () => {
  return (
    <>
      <Layout>
        <div className="pageBanner">
          <PageBannerSection
            bannerImage={whyChooseUsImg}
            pageTitle=""
          ></PageBannerSection>
          <h2>Why Choose Us</h2>
        </div>
        <div className="whyChooseUs_section">
          <div className="page_width">
            <div className="whyChooseUs_section_content">
              <div className="section_subtitle">
                <h3>Al Zawiya Films & Production</h3>
              </div>
              <div className="section_title">
                <h3>Why Choose Us?</h3>
              </div>
              <div className="why_us_p">
                <p>
                  Compelling stories and fresh video ideas grab viewers and
                  boost worldwide watching. Check out the magic of one-of-a-kind
                  quality and artistic brilliance.
                </p>
              </div>
              <div className="why_us_p">
                <p>
                  We're all about top-notch work. Whether it's company flicks,
                  ads, write-ups, or social clips, we tackle each job with the
                  same fire and commitment. Our clients always get knockout
                  results. We bring professional expertise and abilities to
                  every project because we have a 9+ years of experience in the
                  industry.
                </p>
              </div>
              <div className="whyUs_reason">
                <div className="rec_tri">
                  <div className="rectangle">
                    <p>&nbsp;</p>
                    <div className="triangle"></div>
                  </div>
                  <div className="reason_title">
                    <h4>Expertise</h4>
                  </div>
                  <div className="reason_number">
                    <div className="no_circle1">
                      <div className="no_circle2">
                        <h4>01</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="whyUs_reason-text">
                  <p>
                    With years of experience in the industry, we bring
                    professional knowledge and skill to every project.
                  </p>
                </div>
                <div className="whyUs_reason_icon">
                  <GrUserExpert />
                </div>
              </div>
              <div className="whyUs_reason border_right_green">
                <div className="rec_tri">
                  <div className="rectangle">
                    <p>&nbsp;</p>
                    <div className="triangle"></div>
                  </div>
                  <div className="reason_title reason_title_green">
                    <h4>Creativity</h4>
                  </div>
                  <div className="reason_number color_green">
                    <div className="no_circle1">
                      <div className="no_circle2 no_circle2_green">
                        <h4 className="color_green">02</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="whyUs_reason-text">
                  <p>
                    We are skilled at using imaginative narration to provide a
                    unique touch to your video.
                  </p>
                </div>
                <div className="whyUs_reason_icon color_green">
                  <FaLightbulb />
                </div>
              </div>
              <div className="whyUs_reason border_right_blue">
                <div className="rec_tri">
                  <div className="rectangle">
                    <p>&nbsp;</p>
                    <div className="triangle"></div>
                  </div>
                  <div className="reason_title reason_title_blue">
                    <h4>Top Equipment</h4>
                  </div>
                  <div className="reason_number">
                    <div className="no_circle1">
                      <div className="no_circle2 no_circle2_blue">
                        <h4 className="color_blue">03</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="whyUs_reason-text">
                  <p>
                    We use the latest technology and equipment to produce
                    high-quality videos.
                  </p>
                </div>
                <div className="whyUs_reason_icon color_blue">
                  <MdVideoCameraFront />
                </div>
              </div>
              <div className="whyUs_reason">
                <div className="rec_tri">
                  <div className="rectangle">
                    <p>&nbsp;</p>
                    <div className="triangle"></div>
                  </div>
                  <div className="reason_title">
                    <h4>Client Focused</h4>
                  </div>
                  <div className="reason_number">
                    <div className="no_circle1">
                      <div className="no_circle2">
                        <h4>04</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="whyUs_reason-text">
                  <p>
                    Your requirements are very important to us, and we are here
                    to fullfill your requirement.
                  </p>
                </div>
                <div className="whyUs_reason_icon">
                  <FaPersonCircleCheck />
                </div>
              </div>
              <div className="whyUs_reason border_right_green">
                <div className="rec_tri">
                  <div className="rectangle">
                    <p>&nbsp;</p>
                    <div className="triangle"></div>
                  </div>
                  <div className="reason_title reason_title_green">
                    <h4>On Time</h4>
                  </div>
                  <div className="reason_number ">
                    <div className="no_circle1">
                      <div className="no_circle2 no_circle2_green">
                        <h4 className="color_green">05</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="whyUs_reason-text">
                  <p>
                    We ensure timely deliveries of projects without compromising
                    on work quality.
                  </p>
                </div>
                <div className="whyUs_reason_icon color_green">
                  <MdSendTimeExtension />
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="videography_process">
          <div className="page_width">
            <div className="videography_process_content">
              <div className="section_subtitle">
                <h3>Best Video Production Company in Dubai</h3>
              </div>
              <div className="section_title">
                <h3>Our Videography Process</h3>
              </div>
              <div className="why_us_p">
                <p>
                  AL Zawiya Film & Production prides itself on its careful and
                  latest videography approach. We make sure every project runs
                  from day one to the end. Our method blends creativity, tech
                  knowledge, and a keen grasp of what our clients want. We walk
                  you through each phase of making your video - from the first
                  idea to the final product. This hands-on, client-first way of
                  doing things makes AL Zawiya stand out. It lets us craft
                  videos that don't just meet the mark. Also, blow past it
                  leaving a big impression on those who watch.
                </p>
              </div>
              <VerticalTimeline>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "rgb(33, 150, 243)",
                    color: "#fff",
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid  #fff",
                    color: "#fff",
                  }}
                  date="STEP 1"
                  iconStyle={{ background: "red", color: "#fff" }}
                  icon=""
                >
                  <h3 className="vertical-timeline-element-title">
                    Initial Consultation
                  </h3>
                  <p>
                    <span>Client Meeting:</span> We discuss about what the
                    project goal is, who is its audience, the main points to get
                    across the goal, and the big picture of overall project.
                  </p>
                  <p>
                    <span>Creative Brief:</span> We together make a plan that
                    spells out what we're doing when we'll do it, and how much
                    budget is required.
                  </p>
                  <p>
                    <span>Concept Development:</span> Brainstorm ideas and
                    finalize the one that fit with the client requirements.
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="STEP 2"
                  iconStyle={{ background: "red", color: "#fff" }}
                  icon=""
                >
                  <h3 className="vertical-timeline-element-title">
                    Pre-Production
                  </h3>
                  <p>
                    <span>Screenwriting:</span> Write a detailed screenplay that
                    outlines the story and events.
                  </p>
                  <p>
                    <span>Scheduling:</span> Plan appropriate production
                    schedules. Includes ammunition lists, plans, and search
                    areas.
                  </p>
                  <p>
                    <span>Casting:</span> Select the right team members for the
                    project needs.
                  </p>
                  <p>
                    <span>Equipment & Personnel:</span> Put together necessary
                    equipment and hire a production team
                  </p>
                  <p>
                    <span>Permits:</span> Obtain all necessary permits and
                    licenses for locations and activities.
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="STEP 3"
                  iconStyle={{ background: "orange", color: "#fff" }}
                  icon=""
                >
                  <h3 className="vertical-timeline-element-title">
                    Production
                  </h3>
                  <p>
                    <span>Setup:</span> Arrange the stage, lights, and sound
                    gear following the shot list and storyboard.
                  </p>
                  <p>
                    <span>Filming:</span> Record the scenes as intended making
                    sure every shot is top-notch and matches the original idea.
                  </p>
                  <p>
                    <span>Direction:</span> Guide the team to get the right
                    performances and scenes.
                  </p>
                  <p>
                    <span>Review:</span> Keep checking the videos to confirm
                    they meet the project's needs and make changes if necessary.
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="STEP 4"
                  iconStyle={{ background: "orange", color: "#fff" }}
                  icon=""
                >
                  <h3 className="vertical-timeline-element-title">
                    Post-Production
                  </h3>
                  <p>
                    <span>Editing:</span> Put the clips together, remove and put
                    the scenes in order to make a good story
                  </p>
                  <p>
                    <span>Sound Effects:</span> Add the sound effects, voices,
                    and songs to make the video better
                  </p>
                  <p>
                    <span>Color Grading:</span> Change the colors and shades to
                    make sure everything looks right and professional.
                  </p>
                  <p>
                    <span>Visual Effects:</span> If needed add effects on
                    pictures or visuals
                  </p>
                  <p>
                    <span>Reviews & Revisions</span> Show the first edited
                    version to the customer, listen to what they say, and change
                    the content according to the customer requirement.
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--education"
                  date="STEP 5"
                  iconStyle={{ background: "green", color: "#fff" }}
                  icon=""
                >
                  <h3 className="vertical-timeline-element-title">
                    Final Delivery
                  </h3>
                  <p>
                    <span>Approval:</span> Get the last okay from the client
                  </p>
                  <p>
                    <span>Exporting:</span> Make the video in all the needed
                    layouts and sharpness levels
                  </p>
                  <p>
                    <span>Distribution:</span> Handover the project to the
                    customer
                  </p>
                  <p>
                    <span>Archiving:</span> Keep the final files safe for later
                    needs or for future maintainance.
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--education"
                  date="STEP 6"
                  iconStyle={{ background: "green", color: "#fff" }}
                  icon=""
                >
                  <h3 className="vertical-timeline-element-title">
                    Customer Follow Up
                  </h3>
                  <p>
                    <span>Customer Feedback:</span> Collect feedback from
                    customers to identify areas for improvement to improve
                    satisfaction
                  </p>
                  <p>
                    <span>Performance Measurement:</span> If applicable,
                    evaluate video performance through metrics and audience
                    feedback.
                  </p>
                  <p>
                    <span>Relationship Building:</span> Maintain a good
                    relationship with the client for potential future business.
                  </p>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default WhyChooseUs;
