import React from "react";
import "./blog.css";
import Layout from "../../Layout/index.jsx";
import PageBannerSection from "../../global/pageBannerSection/pageBannerSection";
import blogBannerImg from "../../assets/images/blogBanner.jpeg";
import { blogData } from "./blog.js";
import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <>
      <Layout>
        <PageBannerSection
          bannerImage={blogBannerImg}
          pageTitle="Blogs"
        ></PageBannerSection>
        <section className="blogSection">
          <div className="page_width">
            <div className="blogSection_content">
              {blogData.map((item) => (
                <div className="single_blog_data">
                  <Link to={item.link}><img src={item.fImage} alt={item.alt} />
                  <h2>{item.title}</h2></Link>
                  <p>{item.excert}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Blog;
