import React, { useState } from "react";
import "./style.css";
import logo from "./logo.png";
import { FaBars } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { IoMdCall } from "react-icons/io";
import { Link } from "react-router-dom";

const Header = () => {
  const [showNav, setShowNav] = useState(false);
  return (
    <>
      <header>
        <div className="page_width">
          <div className="header_content">
            <div className="logo">
              <Link to="/"><img src={logo} alt="Al Zawiya Films & Production" /></Link>
            </div>
            <nav className="navbar">
              <ul className="navlinks">
                <li>
                  <Link to="/who-we-are">Who We Are</Link>
                </li>
                <div className="what_we_do">
                  <li>
                    <Link to="/what-we-do">What We Do</Link>
                  </li>
                  <div className="what_we_do_content">
                    <ul>
                      <li>
                        <Link to="/timelapse">TimeLapse</Link>
                      </li>
                      <li>
                        <Link to="/360-video-booth">360 Video Booth</Link>
                      </li>
                      <li>
                        <Link to="/reels">Reels</Link>
                      </li>
                      <li>
                        <Link to="/corporate">Corporate</Link>
                      </li>
                      <li>
                        <Link to="/real-estate">Real Estate</Link>
                      </li>
                      <li>
                        <Link to="/headshot">Headshot</Link>
                      </li>
                      <li>
                        <Link to="/product">Product</Link>
                      </li>
                      <li>
                        <Link to="/fashion">Fashion</Link>
                      </li>
                      <li>
                        <Link to="/food">Food</Link>
                      </li>
                      <li>
                        <Link to="/wedding">Wedding</Link>
                      </li>
                      <li>
                        <Link to="/automotive">Automotive</Link>
                      </li>
                      <li>
                        <Link to="/birthday">Birthday</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </nav>
            <div className="contact_us_btn">
              <a href="tel:971555623918"className="contact_btn"><span><IoMdCall /></span> +971 55 562 3918</a>
            </div>
          </div>
        </div>
        <div className="mobile_header_content">
          <div className="logo">
            <Link to="/"><img src={logo} alt="Al Zawiya Films & Production" /></Link>
          </div>
          {showNav === true && (
            <nav className="navbar slide-right">
              <div className="close_icon">
                <IoCloseSharp onClick={() => setShowNav(false)} />
              </div>
              <div className="logo1">
                <Link to="/"><img src={logo} alt="Al Zawiya Films & Production" /></Link>
              </div>
              <ul className="navlinks">
                <li>
                  <Link to="/who-we-are">Who We Are</Link>
                </li>
                <div className="what_we_do_m">
                  <li>
                    <Link to="/what-we-do">What We Do</Link>
                  </li>
                  <div className="what_we_do_content_m">
                    <ul>
                      <li>
                        <Link to="/timelapse">TimeLapse</Link>
                      </li>
                      <li>
                        <Link to="/360-video-booth">360 Video Booth</Link>
                      </li>
                      <li>
                        <Link to="/reels">Reels</Link>
                      </li>
                      <li>
                        <Link to="/corporate">Corporate</Link>
                      </li>
                      <li>
                        <Link to="/realestate">Real Estate</Link>
                      </li>
                      <li>
                        <Link to="/headshot">Headshot</Link>
                      </li>
                      <li>
                        <Link to="/product">Product</Link>
                      </li>
                      <li>
                        <Link to="/fashion">Fashion</Link>
                      </li>
                      <li>
                        <Link to="/food">Food</Link>
                      </li>
                      <li>
                        <Link to="/wedding">Wedding</Link>
                      </li>
                      <li>
                        <Link to="/automotive">Automotive</Link>
                      </li>
                      <li>
                        <Link to="/birthday">Birthday</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </nav>
          )}
          <div className="bars_icon">
            <FaBars onClick={() => setShowNav(true)} />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
