import React from "react";
import "./homestyle.css";

const Portfolio = (props) => {
  return (
    <>
      <div className="carousel_img">
        <img className="product--image product_image" src={props.img} alt="" />
        {/*<h2 className="carousel_card_heading">{props.name}</h2>*/}
      </div>
    </>
  );
};

export default Portfolio;
