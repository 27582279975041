import React from "react";
import "../pageBannerSection/pageBannerSection.css";

const CategoryPageBannerSection = ({bannerImage}) => {
  return (
    <>
      <section className="banner_section">
        <div className="banner_image">
          <img src={bannerImage} alt="Al Zawiya Film & Production" />
        </div>
      </section>
    </>
  );
};

export default CategoryPageBannerSection;
