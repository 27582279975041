import React, {useState} from "react";
import "./faq.css";

const Faq = ({faqDetails}) => {
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  return (
    <>
      <section className="faq_section">
        <div className="page_width">
          <div className="faq_section_content">
            <h4>Our Faqs</h4>
            <h2>Frequently Asked Questions</h2>
            {faqDetails.map((item, i) => (
              <div className="faq_content">
                <button className="faq_question" onClick={() => toggle(i)}>
                  {item.question}
                </button>
                <p
                  className={
                    selected === i
                      ? "faq_collapse_content content_show"
                      : "faq_collapse_content"
                  }
                >
                  {item.answer}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
