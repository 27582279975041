import React, {useState} from 'react'
import { FaStreetView } from "react-icons/fa";
import { FaSmile } from "react-icons/fa";
import { SiEventstore } from "react-icons/si";
import { GiCalendarHalfYear } from "react-icons/gi";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import "./activeClientsSection.css";

const ActiveClientsSection = () => {
    const [counterOn, setCounterOn] = useState(false);
  return (
    <>
    <section className="active_clients_section">
          <div className="page_width">
            <div className="active_clients_content">
              <div className="active_customers">
                <div className="active_icon">
                  <span>
                    <FaStreetView />
                  </span>
                </div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {counterOn && (
                    <h2>
                      <CountUp
                        start={1000}
                        end={10000}
                        duration={2}
                        delay={0}
                      ></CountUp>
                      +
                    </h2>
                  )}
                </ScrollTrigger>
                <h4>No Of Active Clients</h4>
              </div>
              <div className="active_customers">
                <div className="active_icon">
                  <span>
                    <FaSmile />
                  </span>
                </div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {counterOn && (
                    <h2>
                      <CountUp
                        start={1}
                        end={98}
                        duration={2}
                        delay={0}
                      ></CountUp>
                      %
                    </h2>
                  )}
                </ScrollTrigger>
                <h4>Customer Satisfication Rate</h4>
              </div>
              <div className="active_customers">
                <div className="active_icon">
                  <span>
                    <SiEventstore />
                  </span>
                </div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {counterOn && (
                    <h2>
                      <CountUp
                        start={1000}
                        end={5000}
                        duration={2}
                        delay={0}
                      ></CountUp>
                      +
                    </h2>
                  )}
                </ScrollTrigger>
                <h4>Events Covered</h4>
              </div>
              <div className="active_customers">
                <div className="active_icon">
                  <span>
                    <GiCalendarHalfYear />
                  </span>
                </div>
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                >
                  {counterOn && (
                    <h2>
                      <CountUp
                        start={1}
                        end={9}
                        duration={2}
                        delay={0}
                      ></CountUp>
                      +
                    </h2>
                  )}
                </ScrollTrigger>
                <h4>Years in Business</h4>
              </div>
            </div>
          </div>
        </section>
    </>
  )
}

export default ActiveClientsSection;