import React, { useState } from "react";
import Layout from "../../../Layout/index.jsx";
import CategoryPageBannerSection from "../../../global/categoryPageBannerSection/categoryPageBannerSection.jsx";
import CategoryTitleSection from "../../../global/categoryPageBannerSection/categoryTitleSection.jsx";
import Images from "../../../global/reactLightbox/images.jsx";
import "../../Home/homestyle.css";
import ClientSection from "../../../global/clients_section/client_section.jsx";
import ContactSection from "../../../global/contactUsSection/ContactSection.jsx";
import "../services.css";
import degreeImg from "../../../assets/images/360Degree/360Banner.jpeg";
import { degreeImages } from "../../../global/reactLightbox/lightboxData.js";

import img1 from "../../../assets/videos/360DegreeVideos/1.jpeg";
import img2 from "../../../assets/videos/360DegreeVideos/2.jpeg";
import img3 from "../../../assets/videos/360DegreeVideos/3.jpeg";
import img4 from "../../../assets/videos/360DegreeVideos/4.jpeg";
import img5 from "../../../assets/videos/360DegreeVideos/5.jpeg";
import img6 from "../../../assets/videos/360DegreeVideos/6.jpeg";
import img7 from "../../../assets/videos/360DegreeVideos/7.jpeg";
import img8 from "../../../assets/videos/360DegreeVideos/8.jpeg";
import img9 from "../../../assets/videos/360DegreeVideos/9.jpeg";

import v1 from "../../../assets/videos/360DegreeVideos/1.mp4";
import v2 from "../../../assets/videos/360DegreeVideos/2.mp4";
import v3 from "../../../assets/videos/360DegreeVideos/3.mp4";
import v4 from "../../../assets/videos/360DegreeVideos/4.mp4";
import v5 from "../../../assets/videos/360DegreeVideos/5.mp4";
import v6 from "../../../assets/videos/360DegreeVideos/6.mp4";
import v7 from "../../../assets/videos/360DegreeVideos/7.mp4";
import v8 from "../../../assets/videos/360DegreeVideos/8.mp4";
import v9 from "../../../assets/videos/360DegreeVideos/9.mp4";

const Degree = () => {
  const [playVideo21, setPlayVideo21] = useState(false);
  const togglePlayVideo21 = () => {
    setPlayVideo21(!playVideo21);
  };
  const [playVideo22, setPlayVideo22] = useState(false);
  const togglePlayVideo22 = () => {
    setPlayVideo22(!playVideo22);
  };
  const [playVideo23, setPlayVideo23] = useState(false);
  const togglePlayVideo23 = () => {
    setPlayVideo23(!playVideo23);
  };
  const [playVideo24, setPlayVideo24] = useState(false);
  const togglePlayVideo24 = () => {
    setPlayVideo24(!playVideo24);
  };
  const [playVideo25, setPlayVideo25] = useState(false);
  const togglePlayVideo25 = () => {
    setPlayVideo25(!playVideo25);
  };
  const [playVideo26, setPlayVideo26] = useState(false);
  const togglePlayVideo26 = () => {
    setPlayVideo26(!playVideo26);
  };
  const [playVideo27, setPlayVideo27] = useState(false);
  const togglePlayVideo27 = () => {
    setPlayVideo27(!playVideo27);
  };
  const [playVideo28, setPlayVideo28] = useState(false);
  const togglePlayVideo28 = () => {
    setPlayVideo28(!playVideo28);
  };
  const [playVideo29, setPlayVideo29] = useState(false);
  const togglePlayVideo29 = () => {
    setPlayVideo29(!playVideo29);
  };
  return (
    <>
      <Layout>
        <CategoryPageBannerSection
          bannerImage={degreeImg}
        ></CategoryPageBannerSection>
        <CategoryTitleSection catTitle="360 Degree Photography & Videography"></CategoryTitleSection>
        <Images data={degreeImages}></Images>
        <section className="video_section">
          <div className="page_width">
            <div className="video_section_content">
              <div className="video1">
                <button className="video_button" onClick={togglePlayVideo21}>
                  <img src={img1} alt="Reels Videography Services in Dubai" />
                </button>
                {playVideo21 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo21}></div>
                    <div className="video_content1">
                      <video src={v1} width="100%" controls autoplay muted />
                    </div>
                  </div>
                )}
              </div>
              <div className="video2">
                <button className="video_button" onClick={togglePlayVideo22}>
                  <img src={img2} alt="Air Show Highlight" />
                </button>
                {playVideo22 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo22}></div>
                    <div className="video_content1">
                      <video src={v2} width="100%" controls autoplay muted />
                    </div>
                  </div>
                )}
              </div>
              <div className="video3">
                <button className="video_button" onClick={togglePlayVideo23}>
                  <img src={img3} alt="Therapy Reel Book V2" />
                </button>
                {playVideo23 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo23}></div>
                    <div className="video_content1">
                      <video src={v3} width="100%" controls autoplay muted />
                    </div>
                  </div>
                )}
              </div>
              <div className="video4">
                <button className="video_button" onClick={togglePlayVideo24}>
                  <img src={img4} alt="Therapy Board Game Cafe" />
                </button>
                {playVideo24 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo24}></div>
                    <div className="video_content1">
                      <video src={v5} width="100%" controls autoplay muted />
                    </div>
                  </div>
                )}
              </div>
              <div className="video5">
                <button className="video_button" onClick={togglePlayVideo25}>
                  <img src={img5} alt="Edge Reels" />
                </button>
                {playVideo25 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo25}></div>
                    <div className="video_content1">
                      <video src={v4} width="100%" controls autoplay muted />
                    </div>
                  </div>
                )}
              </div>
              <div className="video6">
                <button className="video_button" onClick={togglePlayVideo26}>
                  <img src={img6} alt="Therapy Reel Play Station V5 MBL" />
                </button>
                {playVideo26 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo26}></div>
                    <div className="video_content1">
                      <video src={v6} width="100%" controls autoplay muted />
                    </div>
                  </div>
                )}
              </div>
              <div className="video7">
                <button className="video_button" onClick={togglePlayVideo27}>
                  <img src={img7} alt="GulFood Reels Shoot" />
                </button>
                {playVideo27 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo27}></div>
                    <div className="video_content1">
                      <video src={v7} width="100%" controls autoplay muted />
                    </div>
                  </div>
                )}
              </div>
              <div className="video8">
                <button className="video_button" onClick={togglePlayVideo28}>
                  <img src={img8} alt="Therapy Reel Cold Bevarege" />
                </button>
                {playVideo28 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo28}></div>
                    <div className="video_content1">
                      <video src={v8} width="100%" controls autoplay muted />
                    </div>
                  </div>
                )}
              </div>
              <div className="video9">
                <button className="video_button" onClick={togglePlayVideo29}>
                  <img src={img9} alt="Therapy Reel Cold Bevarege" />
                </button>
                {playVideo29 && (
                  <div className="video_rightside">
                    <div className="overlay" onClick={togglePlayVideo29}></div>
                    <div className="video_content1">
                      <video src={v9} width="100%" controls autoplay muted />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <ContactSection></ContactSection>
        <section className="blog_section">
          <div className="page_width">
            <div className="blog_section_content">
              <h1>Explore 360-Degree Photography and Videography in Dubai</h1>
              <p>
                Imagine a city so stunningly done up in the skies with a posh
                lifestyle and diversified culture. Dubai is keen on being at the
                very top of all technical novelties. One of the many novelties
                in this field that is highly demanded now is
                <a href="/">
                  <span> 360-degree photography </span>
                </a>
                and filmmaking. With immersive technology, the world could also
                be captured differently and present the audience with a holistic
                viewpoint. This blog explains the uses, advantages, and reasons
                why 360-degree photography and filming are the newest trends in
                Dubai across several industries.
              </p>
              <h2>360 Degrees in Photography and Videography</h2>
              <p>
                <span>360-degree photography and videography</span> refer to
                capturing every direction around a camera. The technology,
                unlike the traditional kind of photography and videography that
                capture a limited frame, gives all-around views that one can, as
                a viewer, immediately navigate. This ability to do so is
                facilitated by specialized cameras possessing numerous lenses,
                each taking different angles.
              </p>
              <h2>
                Why 360-Degree Photography and Videography Are Best Done in
                Dubai?
              </h2>
              <p>
                Dubai has many wonderful places to shoot in 360 degrees—from the
                serene Jumeirah beaches to the energetic downtown. 360-degree
                content could provide a range of backdrop-worthy locations to
                give birth to visually stunning, dynamic material. Besides,
                Dubai's modern and high-class technology can ensure that the
                videos and photos produced are top quality.
              </p>
              <h2> Benefits of 360 Degree Videography and Photography</h2>
              <h3>Immersive Experience</h3>
              <p>
                The greatest merit of <span>360 degree photography</span> and
                filming lies in its immersive perspective. It will function well
                with virtual tours and experiential marketing to give viewers a
                sense of a location, as if they were there, with the most
                thorough perspective available.
              </p>
              <h3>Higher User Engagement</h3>
              <p>
                Interactive content can engage and capture viewers for a longer
                period. 360-degree immersive films and images are guaranteed to
                stimulate more responses from viewers since they allow them to
                engage with the material in real-time, which improves the
                experiences that are stored in their memories.
              </p>
              <h3>Comprehensive View</h3>
              <p>
                <span>360-degree photography and videography</span> provide a
                full shot of view, in contrast to traditional media limited by
                the camera angle. This works especially for real estate,
                tourism, event coverage, etc., where the full environment has to
                be portrayed.
              </p>
              <h3>A New Marketing Tool</h3>
              <p>
                Dubai is a very competitive market, and a business has to come
                up with something different. 360-degree content is a new
                marketing tool for products and services of locations, making
                the companies stand out from each other.
              </p>
              <h2>360 Videography and Photography Applications in Dubai</h2>
              <h3>Real Estate</h3>
              <p>
                <span>360-degree videography and photography</span> give buyers
                a virtual tour of the property, letting them see all the rooms
                and details without physically visiting the property. It
                heightens the buying experience while helping a real estate
                agent target buyers worldwide.
              </p>
              <p>
                Imagine being an agent representing a high-end penthouse
                overlooking the Palm Jumeirah or a marvelous, sprawling villa in
                Emirates Hills. To these buyers, 360-degree videos and photos
                would now give prospective buyers the grandeur and intricacies
                of the architecture as if they were present in person.
              </p>
              <h3>Tourism</h3>
              <p>
                Dubai stands out among those tourist places that use the charm
                of 360⁰ content representations, which can boost their
                attractiveness with immersive experiences. Virtual tours to
                landmarks, hotels, and activities can attract and help
                effectively plan trips.
              </p>
              <p>
                They will also get assistance from the virtual tour facility to
                feel the atmosphere of Burj Khalifa, Dubai Mall, and Dubai
                Fountain. Virtual walkthroughs in hotels and resorts, covering
                their rooms and facilities, give guests a clear perspective of
                what to expect in accommodation.
              </p>
              <h3>Events/Weddings</h3>
              <p>
                Events and weddings are shot in 360 degrees. Therefore, everyone
                can experience it again from a completely different perspective.
                It certainly is a special way to preserve special moments, by
                providing a view of the celebrations from all perspectives.
              </p>
              <p>
                From the Dubai World Trade Centre during corporate events to the
                Burj Al Arab during grandeur weddings,
                <span> 360 degree videography </span>
                and photography capture every nuance and emotion. Guests who
                were there are taken back to it; for those who couldn't be
                there, it happens right in front of them.
              </p>
              <h3>Corporate and Marketing</h3>
              <p>
                Now, companies can create 360-degree videos and photos for
                product launches, corporate events, or marketing campaigns to
                allow audiences to intercede with the content so that they can
                make the brand storytelling more engaging, hence leaving a
                long-lasting impression on the audiences' minds.
              </p>
              <p>
                A technology company keen on launching a new product has the
                potential for 360-degree video presentations concerning features
                and functionality. A fashion brand could create immersive
                lookbooks or, for that matter, even virtual fashion shows to
                give one-of-a-kind promises in showcasing their collections.
              </p>
              <h2>
                How to Choose The 360 Degree Videography & Photography Service
                in Dubai?
              </h2>
              <p>
                As one considers choosing the right{" "}
                <span>
                  360-degree videography and photography service in Dubai
                </span>
                , the following are some things to keep in mind:
              </p>
              <h3>Experience and Knowledge</h3>
              <p>
                They need to prove they've done this before, providing
                360-degree content
              </p>
              <h3>Portfolio</h3>
              <p>
                Look at past works and be assured the style and quality of their
                output is per your expectations.
              </p>
              <h3>Technology and Equipment</h3>
              <p>Must have the best cameras and editing software.</p>
              <h3>Client Testimonials</h3>
              <p>
                Browse through client reviews and testimonials to understand how
                happy they were with the deliverables.
              </p>
              <h2>Tips to Create Beautiful 360 Degree Content</h2>
              <h3>Shot Listing</h3>
              <p>
                Planning for site and scene setup is necessary for 360-degree
                photography and filmmaking. This includes a thought process
                about the location, lighting, and key components in a frame.
              </p>
              <h3>Good Equipment</h3>
              <p>
                Buy a good 360-degree camera and accessories, so you capture the
                best quality. A professional raw can enhance the final much more
                than you can expect from a normal raw.
              </p>
              <h3>Focus on Storytelling</h3>
              <p>
                Focus on Even in a 360° format, the story must be central.
                Consider carefully the story that you wish to tell and how your
                viewer is going to engage with what you're
                producing.Storytelling
              </p>
              <h3>Editing</h3>
              <p>
                Editing Make sure your 360-degree video is optimized to work on
                a host of platforms, from virtual reality headsets to Facebook
                and YouTube.
              </p>
              <h3>Optimize for Multiple Platforms</h3>
              <p>
                Ensure your 360-degree video works on headsets ranging from
                virtual reality to Facebook and YouTube.
              </p>
              <h2>Future of 360-Degree Photography and Videography in Dubai</h2>
              <p>
                The use of <span>360 degree photography</span> and filming is
                growing due to the development of technology. content will take
                specific tools and certain skills. Be very sensitive about
                stitching, transitions, or even the slightest touch-ups you may
                want to make so that you experience smoothness.
              </p>
              <p>
                360 Degree Photography and Videography in Dubai: The Future
                Ahead 360-degree photography and filming have been increasingly
                used due to advancing technology. Interactive and way more
                immersive experiences beckon through augmented reality and
                virtual reality.
              </p>
              <h2>AR and VR Integration</h2>
              <p>
                Future developments in 360-degree content augmented and virtual
                reality will only push the envelope towards even further
                immersion, which most definitely shall include prospects of
                walking through properties that prospective homebuyers do with
                VR headsets and looking at everything in an extremely realistic
                way.
              </p>
              <h2>360-Degree Live Streaming</h2>
              <p>
                360-degree live streaming is increasingly becoming famous and
                enabling real-time immersive experiences. Now imagine a live
                streaming of a concert a sporting event or even a conference in
                360 degrees, allowing viewers from across the globe to feel part
                of the event.
              </p>
              <h2>Improved Interactivity</h2>
              <p>
                Future enhancements could include improved interactivity
                features where 360-degree video viewers can click on hotspots to
                access further information or even be driven to other related
                content accordingly.
              </p>
              <h2>Conclusion</h2>
              <p>
                The 360 video and photography filming in Dubai provide the most
                creative and engaging way of experiencing and capturing the
                world. From real estate and tourism to event capturing and
                corporate marketing, this technology allows a different
                perspective, which could be most engaging and involving for any
                audience. Dubai can help you create compelling 360-degree
                content with immense appeal and long-lasting impact, given its
                stunning backdrops and top-of-the-line facilities.
              </p>
              <h2>Drop Us a Line</h2>
              <p>
                Want to enter the world of
                <a
                  href="https://www.linkedin.com/company/zawiyafilms/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span> 360 degree video and photography in Dubai</span>
                </a>
                ? Welcome to our service inquiry. We at the blog will be pleased
                to discuss with you how we may help to facilitate the production
                on your behalf. Therefore, you can create such immersive content
                catering to your unique audience.
              </p>
              <p>
                Come, and experience the world of
                <span> 360 degree videography and photography </span> with us.
                See a world of increased engagement and interactivity using our
                360-degree content in this pulsating city of Dubai. Each angle
                caught ensures that no detail is ever missed out on to give your
                audience an unrivaled experience. It is effective for real
                estate, travel, event recording, and even enhancing your
                business's marketing plan. Use this technology, and watch your
                digital presence skyrocket to new heights.
              </p>
            </div>
          </div>
        </section>
        <ClientSection></ClientSection>
      </Layout>
    </>
  );
};

export default Degree;
