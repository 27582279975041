import React from "react";
import "./client_section.css";


const Clients = (props) => {
  return (
    <>
      <div className="clients_img">
        <img className="product--image cli_image" src={props.img} alt="" />
      </div>
    </>
  )
}

export default Clients;