import React, { useState } from "react";
import "./getaQuote.css";
import Layout from "../../Layout/index.jsx";
import PageBannerSection from "../../global/pageBannerSection/pageBannerSection.jsx";
import getQuoteImg from "../../assets/images/get_a_quote.jpeg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Testimonial from "../../global/testimonial/testimonial.jsx";
import ClientSection from "../../global/clients_section/client_section.jsx";
import axios from "axios";

const GetaQuote = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [service, setService] = useState("");
  const [country, setCountry] = useState("ae");
  const [companyName, setCompanyName] = useState("");
  const [budget, setBudget] = useState("");
  const [checkedValue, setCheckedValue] = useState([]);
  const [fullNameError, setFullNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [companyNameError, setCompanyNameError] = useState(null);
  const [servicesError, setServicesError] = useState(null);
  const [budgetError, setBudgetError] = useState(null);
  const [checkboxError, setCheckboxError] = useState(null);
  const [error, setError] = useState(null);
  const [submit, setSubmit] = useState(null);

  const handleOnChange = (value, countryObj, e, formattedValue) => {
    if (country === countryObj?.countryCode) {
      setNumber(formattedValue);
      console.log("Number:", number);
    } else {
      setNumber("");
      setCountry(countryObj?.countryCode);
    }
    /*console.log(value, countryObj, formattedValue);*/
    console.log("countryObj:", countryObj);
    console.log("Value:", value);
    console.log("Formatted Value:", formattedValue);
  };

  const handleCheck = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setCheckedValue([...checkedValue, value]);
    } else {
      setCheckedValue(checkedValue.filter((item) => item !== value));
    }
  };

  const formData1 = {
    fullName: fullName,
    email: email,
    phone: number,
    companyName: companyName,
    desiredService: service,
    budget: budget,
    mainService: checkedValue,
  };
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const submitData1 = () => {
    if (fullName === "") {
      setFullNameError("Please Enter FullName");
      setError("");
      setSubmit("");
    }
    if (email === "") {
      setEmailError("Please Enter Email");
      setError("");
      setSubmit("");
    }
    if (number === "") {
      setPhoneError("Please Enter Phone");
      setError("");
      setSubmit("");
    }
    if (companyName === "") {
      setCompanyNameError("Plaese Enter Your Company Name");
      setError("");
      setSubmit("");
    }
    if (service === "") {
      setServicesError("Please Enter A Valid Service");
      setError("");
      setSubmit("");
    }
    if (budget === "") {
      setBudgetError("Please Select Some Budget Value");
      setError("");
      setSubmit("");
    }
    if (checkedValue.length === 0) {
      setCheckboxError("Select A Checkbox Value");
      setError("");
      setSubmit("");
    }
    if (fullName !== "") {
      setFullNameError("");
      setSubmit("");
      setError("");
    }
    if (email !== "") {
      setEmailError("");
      setSubmit("");
      setError("");
    }
    if (number !== "") {
      setPhoneError("");
      setSubmit("");
      setError("");
    }
    if (companyName !== "") {
      setCompanyNameError("");
      setSubmit("");
      setError("");
    }
    if (service !== "") {
      setServicesError("");
      setSubmit("");
      setError("");
    }
    if (budget !== "") {
      setBudgetError("");
      setSubmit("");
      setError("");
    }
    if (checkedValue.length !== 0) {
      setCheckboxError("");
      setSubmit("");
      setError("");
    }
    if (
      fullName === "" &&
      email === "" &&
      number === "971" &&
      companyName === "" &&
      service === "" &&
      budget === "" &&
      checkedValue.length === 0
    ) {
      setError("Please fill all Fields");
      setFullNameError("");
      setBudgetError("");
      setSubmit("");
      setEmailError("");
      setPhoneError("");
      setCompanyNameError("");
      setServicesError("");
      setCheckboxError("");
    }
    if (
      fullName !== "" &&
      email !== "" &&
      number !== "" &&
      companyName !== "" &&
      service !== "" &&
      budget !== "" &&
      checkedValue.length !== 0
    ) {
      if (emailRegex.test(email)) {
        axios
          .post("http://localhost:4000/create_quotation", formData1)
          .then(function (response) {
            console.log(response);
            setSubmit("Form Submitted");
            setError("");
            setFullNameError("");
            setBudgetError("");
            setEmailError("");
            setPhoneError("");
            setCompanyNameError("");
            setServicesError("");
            setCheckboxError("");
            setFullName("");
            setEmail("");
            setNumber("971");
            setCompanyName("");
            setService("");
            setBudget("");
            setCheckedValue([]);
          })
          .catch(function (error) {
            console.log(error);
            setError(error);
          });
        console.log("Form Data", formData1);
      } else {
        setEmailError("Please Enter Some Valid Email");
      }
    }
  };
  return (
    <>
      <Layout>
        <PageBannerSection bannerImage={getQuoteImg} pageTitle="Get A Quote" />
        <section className="getaQuote">
          <div className="page_width">
            <div className="getaQuote_content">
              <div className="getQuote_title">
                <h2> Get A Quote </h2>
              </div>
              <p className="error pad-left">{error}</p>
              <p className="green pad-left">{submit}</p>
              <div className="input_fields">
                <p className="error">{fullNameError}</p>
                <div className="fullName">
                  <input
                    type="text"
                    name="fullName"
                    className="fullName"
                    placeholder="Full Name *"
                    value={fullName}
                    onChange={(e) => {
                      setFullName(e.target.value);
                      console.log("Input Data:", e.target.value);
                    }}
                  />
                  <p className="error">{emailError}</p>
                  <input
                    type="email"
                    name="email"
                    className="email"
                    placeholder="Email *"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      console.log("Input Data:", e.target.value);
                    }}
                  />
                  <p className="error">{phoneError}</p>
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                      className: "phone1",
                    }}
                    inputStyle={{
                      background: "#66737e",
                      width: "100%",
                    }}
                    countryCodeEditable={false}
                    country={country}
                    value={number}
                    onChange={(value, countryObj, e, formattedValue) =>
                      handleOnChange(value, countryObj, e, formattedValue)
                    }
                  />
                  <p className="error">{companyNameError}</p>
                  <input
                    type="text"
                    name="companyName"
                    className="companyName"
                    placeholder="Company Name *"
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      console.log("Input Data:", e.target.value);
                    }}
                  />
                  <p className="error">{servicesError}</p>
                  <select
                    name="services"
                    id="services"
                    className="select_input"
                    value={service}
                    onChange={(e) => {
                      setService(e.target.value);
                      console.log("Input Data:", e.target.value);
                    }}
                  >
                    <option value="">Select Your Desired Service</option>
                    <option value="timelapse videography">
                      Timelapse Videography
                    </option>
                    <option value="360 video booth videography">
                      360 Video Booth Videography
                    </option>
                    <option value="reels videography">Reels Videography</option>
                    <option value="wedding">Wedding</option>
                    <option value="food photography">Food Photography</option>
                    <option value="product photography">
                      Product Photography
                    </option>
                    <option value="engagement shoots">Engagement Shoots</option>
                    <option value="bridal shower">Bridal Shower</option>
                    <option value="maternity baby shower">
                      Maternity Baby Shower
                    </option>
                    <option value="birthday photoshoots">
                      Birthday Photoshoots
                    </option>
                    <option value="business portraits portfolio">
                      Business Portraits Portfolio
                    </option>
                    <option value="fashion photoshoot">
                      Fashion Photoshoot
                    </option>
                    <option value="real estate photography">
                      Real Estate Photography{" "}
                    </option>
                    <option value="building shoots">
                      Building Shoots & Architectural Photography
                    </option>
                    <option value="exhibition shoot">Exhibition Shoot</option>
                    <option value="conferences shoot">Conferences Shoot</option>
                    <option value="automotive photography">
                      Automotive Photography
                    </option>
                    <option value="event coverage">Event Coverage</option>
                  </select>
                  <p className="error">{budgetError}</p>
                  <select
                    name="budget"
                    id="budget"
                    className="budget_input"
                    value={budget}
                    onChange={(e) => {
                      setBudget(e.target.value);
                      console.log("Input Data:", e.target.value);
                    }}
                  >
                    <option value="">What is your Approximate Budget?</option>
                    <option value="500 - 1000">500 - 1000</option>
                    <option value="1000 - 2000">1000 - 2000</option>
                    <option value="2000 - 3000">2000 - 3000</option>
                    <option value="3000 - 4000">3000 - 4000</option>
                    <option value="other">Other</option>
                  </select>
                  <h3>I NEED THE FOLLOWING SERVICE</h3>
                  <p className="error">{checkboxError}</p>
                  <div className="photography">
                    <div>
                      <input
                        type="checkbox"
                        id="photography"
                        name="checkbox"
                        value="Photography"
                        checked={checkedValue.includes("Photography")}
                        onChange={handleCheck}
                      />
                    </div>
                    <div>
                      <label for="photography">Photography</label>
                    </div>
                  </div>
                  <div className="videography">
                    <div>
                      <input
                        type="checkbox"
                        id="videography"
                        name="checkbox"
                        value="Videography"
                        checked={checkedValue.includes("Videography")}
                        onChange={handleCheck}
                      />
                    </div>
                    <div>
                      <label for="videography">Videography</label>
                    </div>
                  </div>
                  <button className="getaQuoteBtn" onClick={submitData1}>
                    Get A Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Testimonial></Testimonial>
        <ClientSection></ClientSection>
      </Layout>
    </>
  );
};

export default GetaQuote;
