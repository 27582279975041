import React, { useState } from "react";
import "./commentsForm.css";
import axios from "axios";

const ReplyForm = ({id}) => {
  let iD = JSON.stringify(id);
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");

  const [error, setError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [commentError, setCommentError] = useState(null);
  const [submit, setSubmit] = useState(null);
  const [date, setDate] = useState(new Date());

  const formData = {
    text: comment,
    name: name,
    email: email,
    website: website,
    date: date.toLocaleDateString(),
    likes: 0,
    dislikes: 0,
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const postComment = () => {
    if (name === "") {
      setNameError("Please Enter Your Name");
      setError("");
      setSubmit("");
    }
    if (email === "") {
      setEmailError("Please Enter Your Email");
      setError("");
      setSubmit("");
    }
    if (comment === "") {
      setCommentError("Please Enter Some Comment");
      setError("");
      setSubmit("");
    }
    if (name !== "") {
      setNameError("");
    }
    if (email !== "") {
      setEmailError("");
    }
    if (comment !== "") {
      setCommentError("");
    }
    if (name === "" && email === "" && comment === "") {
      setError("Please Fill All Required Fields");
      setNameError("");
      setEmailError("");
      setCommentError("");
      setSubmit("");
    }
    if (name !== "" && email !== "" && comment !== "") {
        console.log("ID:", id);
      if (emailRegex.test(email)) {
        axios
          .put(`http://localhost:4000/update_comment/${id}/reply`, formData)
          .then(function (response) {
            console.log(response);
            setSubmit("Form Submitted");
            setError("");
            setNameError("");
            setEmailError("");
            setCommentError("");
            setName("");
            setEmail("");
            setComment("");
            setWebsite("");
          })
          .catch(function (error) {
            console.log(error);
            setError(error);
          });
        console.log(formData);
      } else {
        setEmailError("Please Enter Some Valid Email");
      }
    }
  };

  return (
    <>
      <div className="commentsform_section_content">
        <h2>Leave A Comment</h2>
        <p className="marginTop">
          Your email address will not be published. Required fields are marked *
          Type here..
        </p>
        <p className="error">{error}</p>
        <p className="error">{commentError}</p>
        <p className="submit">{submit}</p>
        <div className="comment">
          <textarea
            name="comment"
            className="comment"
            placeholder="Type Here ... *"
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
              console.log("Input Data:", e.target.value);
            }}
          />
        </div>
        <p className="error">{nameError}</p>
        <p className="error">{emailError}</p>
        <div className="inputs">
          <input
            type="text"
            name="name"
            className="name"
            placeholder="Name *"
            autoComplete="Given Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              console.log("Input Data:", e.target.value);
            }}
          />
          <input
            type="email"
            name="email"
            className="email"
            placeholder="Email *"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              console.log("Input Data:", e.target.value);
            }}
          />
          <input
            type="website"
            name="website"
            className="website"
            placeholder="Website"
            value={website}
            onChange={(e) => {
              setWebsite(e.target.value);
              console.log("Input Data:", e.target.value);
            }}
          />
        </div>
        <button className="postCommentBtn" onClick={() => postComment()}>
          Post Comment
        </button>
      </div>
    </>
  );
};

export default ReplyForm;
