import React from "react";
import { useNavigate } from "react-router-dom";
import "./contactSection.css";

const ContactSection = () => {
  const navigate = useNavigate();
  const gotToNewPage = () => {
    navigate("/contact-us");
  };
  return (
    <>
      <section className="get_quote_section">
        <div className="page_width">
          <div className="get_quote_section_content">
            <div>
              <h2>
                Are You Searching For Good Photography & Videography Services in
                Dubai?
              </h2>
            </div>
            <div>
              <button onClick={() => gotToNewPage()}>Contact Us</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactSection;
