import React, { useState } from "react";
import ClientSection from "../../global/clients_section/client_section.jsx";
import Layout from "../../Layout/index.jsx";
import "./contact_us.css";
import PageBannerSection from "../../global/pageBannerSection/pageBannerSection.jsx";
import contactUs from "../../assets/images/contactUs.jpeg";
import { IoLocation } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";

const ContactUs = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");
  const [country, setCountry] = useState("ae");
  const [error, setError] = useState(null);
  const [submit, setSubmit] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [messageError, setMessageError] = useState(null);

  const handleOnChange = (value, countryObj, e, formattedValue) => {
    if (country === countryObj?.countryCode) {
      setNumber(formattedValue);
      console.log("Number:", number);
    } else {
      setNumber("");
      setCountry(countryObj?.countryCode);
    }
    /*console.log(value, countryObj, formattedValue);*/
    console.log("countryObj:", countryObj);
    console.log("Value:", value);
    console.log("Formatted Value:", formattedValue);
  };

  const formData = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: number,
    message: message,
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const submitData = () => {
    if (firstName === "") {
      setFirstNameError("Please Enter First Name");
      setError("");
      setSubmit("");
    }
    if (lastName === "") {
      setLastNameError("Please Enter Last Name");
      setError("");
      setSubmit("");
    }
    if (email === "") {
      setEmailError("Please Enter Email");
      setError("");
      setSubmit("");
    }
    if (number === "") {
      setPhoneError("Please Enter Valid Phone");
      setError("");
      setSubmit("");
    }
    if (message === "") {
      setMessageError("Please Enter Some Message");
      setError("");
      setSubmit("");
    }
    if (firstName !== "") {
      setFirstNameError("");
      setSubmit("");
      setError("");
    }
    if (lastName !== "") {
      setLastNameError("");
      setSubmit("");
      setError("");
    }
    if (number !== "") {
      setPhoneError("");
      setSubmit("");
      setError("");
    }
    if (message !== "") {
      setMessageError("");
      setSubmit("");
      setError("");
    }
    if (
      firstName === "" &&
      lastName === "" &&
      email === "" &&
      number === "971" &&
      message === ""
    ) {
      setError("Plesae fill all fields");
      setMessageError("");
      setSubmit("");
      setPhoneError("");
      setEmailError("");
      setLastNameError("");
      setFirstNameError("");
      setSubmit("");
    }
    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      number !== "" &&
      message !== ""
    ) {
      if (emailRegex.test(email)) {
        axios
          .post("http://localhost:4000/create_contact", formData)
          .then(function (response) {
            console.log(response);
            setSubmit("Form Submitted");
            setMessageError("");
            setPhoneError("");
            setEmailError("");
            setLastNameError("");
            setFirstNameError("");
            setError("");
            setFirstName("");
            setLastName("");
            setEmail("");
            setNumber("971");
            setMessage("");
          })
          .catch(function (error) {
            console.log(error);
            setError(error);
          });
        console.log("Input Data:", formData);
      }
      else {
        setEmailError("Please Enter Some Valid Email");
      }
    }
  };

  return (
    <>
      <Layout>
        <PageBannerSection bannerImage={contactUs} pageTitle="Contact Us" />
        <section className="contact_details_section">
          <div className="page_width">
            <div className="contact_details_content">
              <div className="office_location">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14433.750135927854!2d55.3015792!3d25.255863!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f433953808e5d%3A0x16685017191ea9de!2sAl%20Zawiya%20Film&#39;s%20Production!5e0!3m2!1sen!2sae!4v1719003357000!5m2!1sen!2sae"
                  width="100%"
                  height="390px"
                  title="Video Production Company In Dubai"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="contact_details">
                <h2>GET IN TOUCH</h2>
                <h4>
                  Let’s get started, Get in touch with our expert support team
                  who will answer all your questions.
                </h4>
                <div className="address">
                  <div className="location_icon">
                    <IoLocation />
                  </div>
                  <h4>
                    Address: Office 206, Al Attar Grand Building, Bank Street,
                    Burjuman, Dubai - UAE.
                  </h4>
                </div>
                <div className="address">
                  <div className="location_icon">
                    <FaPhoneAlt />
                  </div>
                  <h4>
                    Phone : <a href="tel:971555622918">+971 55 562 2918</a>
                  </h4>
                </div>
                <div className="address">
                  <div className="location_icon">
                    <IoMdMail />
                  </div>
                  <h4>
                    Email :{" "}
                    <a href="mailto:imran@zawiyafilms.ae">
                      imran@zawiyafilms.ae
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="form_section">
          <div className="page_width">
            <div className="form_section_content">
              <div className="contactForm_title">
                <h2> Contact Form</h2>
              </div>
              <p className="error">{error}</p>
              <p className="submit">{submit}</p>
              <p className="error">{firstNameError}</p>
              <p className="error">{lastNameError}</p>
              <p className="error">{emailError}</p>
              <p className="error">{phoneError}</p>
              <p className="error">{messageError}</p>

              <div className="name_field">
                <div className="firstName">
                  <input
                    type="text"
                    name="firstName"
                    className="firstName"
                    placeholder="First Name *"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      console.log("Input Data:", e.target.value);
                    }}
                  />
                </div>
                <div className="lastName">
                  <input
                    type="text"
                    name="lastName"
                    className="lastName"
                    placeholder="Last Name *"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      console.log("Input Data:", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="name_field">
                <input
                  type="email"
                  name="email"
                  className="email"
                  placeholder="Email *"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    console.log("Input Data:", e.target.value);
                  }}
                />
                <div className="phone">
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    inputStyle={{
                      background: "#66737e",
                      width: "96%",
                    }}
                    countryCodeEditable={false}
                    country={country}
                    value={number}
                    onChange={(value, countryObj, e, formattedValue) =>
                      handleOnChange(value, countryObj, e, formattedValue)
                    }
                  />
                </div>
              </div>
              <div className="message">
                <textarea
                  name="message"
                  className="message"
                  placeholder="Message..."
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    console.log("Input Data:", e.target.value);
                  }}
                />
              </div>
              <div className="submit_button">
                <button onClick={submitData}>Submit</button>
              </div>
            </div>
          </div>
        </section>
        <ClientSection></ClientSection>
      </Layout>
    </>
  );
};

export default ContactUs;
