import React from "react";
import { MdSendTimeExtension } from "react-icons/md";
import { FaPersonCircleCheck } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";
import { MdVideoCameraFront } from "react-icons/md";
import { FaLightbulb } from "react-icons/fa";
import "./whyChooseUsSection.css";
import { useNavigate } from "react-router-dom";

const WhyChooseUsSection = () => {
  const navigate = useNavigate();
  const gotToNewPage = () => {
    navigate("/why-us");
  };
  return (
    <>
      <section className="why_us_section">
        <div className="page_width">
          <div className="why_us_content">
            <div className="section_subtitle">
              <h3>Al Zawiya Films & Production</h3>
            </div>
            <div className="section_title">
              <h2>Why Choose Us?</h2>
            </div>
            <div className="why_us_p">
              <p>
                Persuasive content and innovative video content creation that
                engages audiences and drives global consumption. Explore the
                power of unique quality and creative excellence.
              </p>
            </div>
            <div className="why_us_p">
              <p>
                We are dedicated to providing excellence. From corporate videos
                and advertising to editorials and social media reels , we
                approach every project with the same dedication and passion,
                ensuring our clients get the best results.
              </p>
            </div>
            <div className="why_us_cards_content">
              <div className="why_us_card slide-right">
                <div className="icon_box">
                  <FaLightbulb />
                </div>
                <div className="card_title">Creativity</div>
                <div className="card_text">
                  Innovative and Unique Storytelling
                </div>
                <div className="card_button">
                  <button onClick={() => gotToNewPage()}>
                    <FaArrowRight />
                  </button>
                </div>
              </div>
              <div className="why_us_card slide-right">
                <div className="icon_box">
                  <MdVideoCameraFront />
                </div>
                <div className="card_title">Top Equipment</div>
                <div className="card_text">
                  Latest technology for high-quality videos
                </div>
                <div className="card_button">
                  <button onClick={() => gotToNewPage()}>
                    <FaArrowRight />
                  </button>
                </div>
              </div>
              <div className="why_us_card slide-right">
                <div className="icon_box">
                  <FaPersonCircleCheck />
                </div>
                <div className="card_title">Client-Focused</div>
                <div className="card_text">Your vision is our priority</div>
                <div className="card_button">
                  <button onClick={() => gotToNewPage()}>
                    <FaArrowRight />
                  </button>
                </div>
              </div>
              <div className="why_us_card slide-right">
                <div className="icon_box">
                  <MdSendTimeExtension />
                </div>
                <div className="card_title">On Time</div>
                <div className="card_text">Reliable and timely delivery</div>
                <div className="card_button">
                  <button onClick={() => gotToNewPage()}>
                    <FaArrowRight />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyChooseUsSection;
