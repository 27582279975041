import pimg1 from "../../assets/images/Untitled.png";
import cimg1 from "../../assets/images/commercial.png";
//Import Birthday Images Start
import bImg1 from "../../assets/images/birthday/1.jpg";
import bImg2 from "../../assets/images/birthday/2.jpg";
import bImg3 from "../../assets/images/birthday/3.jpg";
import bImg4 from "../../assets/images/birthday/4.jpg";
import bImg5 from "../../assets/images/birthday/5.jpg";
import bImg6 from "../../assets/images/birthday/6.jpg";
import bImg7 from "../../assets/images/birthday/7.jpg";
import bImg8 from "../../assets/images/birthday/8.jpg";
import bImg9 from "../../assets/images/birthday/9.jpg";
import bImg10 from "../../assets/images/birthday/10.jpg";
import bImg11 from "../../assets/images/birthday/11.jpg";
import bImg12 from "../../assets/images/birthday/12.jpg";
import bImg13 from "../../assets/images/birthday/13.jpg";
//Import Birthday Images End

//Import Corporate Images Start
import cImg1 from "../../assets/images/corporate/1.jpg";
import cImg2 from "../../assets/images/corporate/2.jpg";
import cImg3 from "../../assets/images/corporate/3.jpg";
import cImg4 from "../../assets/images/corporate/4.jpg";
import cImg5 from "../../assets/images/corporate/5.jpg";
import cImg6 from "../../assets/images/corporate/6.jpg";
import cImg7 from "../../assets/images/corporate/7.jpg";
import cImg8 from "../../assets/images/corporate/8.jpg";
import cImg9 from "../../assets/images/corporate/9.jpg";
import cImg10 from "../../assets/images/corporate/10.jpg";
import cImg11 from "../../assets/images/corporate/11.jpg";
import cImg12 from "../../assets/images/corporate/12.jpg";
import cImg13 from "../../assets/images/corporate/13.jpg";
import cImg14 from "../../assets/images/corporate/14.jpg";
import cImg15 from "../../assets/images/corporate/15.jpg";
import cImg16 from "../../assets/images/corporate/16.jpg";
import cImg17 from "../../assets/images/corporate/17.jpg";
import cImg18 from "../../assets/images/corporate/18.jpg";
import cImg19 from "../../assets/images/corporate/19.jpg";
import cImg20 from "../../assets/images/corporate/20.jpg";
import cImg21 from "../../assets/images/corporate/21.jpg";
import cImg22 from "../../assets/images/corporate/22.jpg";
import cImg23 from "../../assets/images/corporate/23.jpg";
import cImg24 from "../../assets/images/corporate/24.jpg";
import cImg25 from "../../assets/images/corporate/25.jpg";
import cImg26 from "../../assets/images/corporate/26.jpg";
import cImg27 from "../../assets/images/corporate/27.jpg";
import cImg28 from "../../assets/images/corporate/28.jpg";
import cImg29 from "../../assets/images/corporate/29.jpg";
//Import Corporate Images End

//Import Headshot Images Start
import hsImg1 from "../../assets/images/headshot/1.jpg";
import hsImg2 from "../../assets/images/headshot/2.jpg";
import hsImg3 from "../../assets/images/headshot/3.jpg";
import hsImg4 from "../../assets/images/headshot/4.jpg";
import hsImg5 from "../../assets/images/headshot/5.jpg";
import hsImg6 from "../../assets/images/headshot/6.jpg";
import hsImg7 from "../../assets/images/headshot/7.jpg";
import hsImg8 from "../../assets/images/headshot/8.jpg";
import hsImg9 from "../../assets/images/headshot/9.jpg";
import hsImg10 from "../../assets/images/headshot/10.jpg";
import hsImg11 from "../../assets/images/headshot/11.jpg";
import hsImg12 from "../../assets/images/headshot/12.jpg";
import hsImg13 from "../../assets/images/headshot/13.jpg";
import hsImg14 from "../../assets/images/headshot/14.jpg";
import hsImg15 from "../../assets/images/headshot/15.jpg";
import hsImg16 from "../../assets/images/headshot/16.jpg";
import hsImg17 from "../../assets/images/headshot/17.jpg";
import hsImg18 from "../../assets/images/headshot/18.jpg";
import hsImg19 from "../../assets/images/headshot/19.jpg";
import hsImg20 from "../../assets/images/headshot/20.jpg";
import hsImg21 from "../../assets/images/headshot/21.jpg";
import hsImg22 from "../../assets/images/headshot/22.jpg";
import hsImg23 from "../../assets/images/headshot/23.jpg";
import hsImg24 from "../../assets/images/headshot/24.jpg";
//Import Headshot Images End

//Import Fashion Images Start
import fImg1 from "../../assets/images/fashion/1.jpg";
import fImg2 from "../../assets/images/fashion/2.jpg";
import fImg3 from "../../assets/images/fashion/3.jpg";
import fImg4 from "../../assets/images/fashion/4.jpg";
import fImg5 from "../../assets/images/fashion/5.jpg";
import fImg6 from "../../assets/images/fashion/6.jpg";
import fImg7 from "../../assets/images/fashion/7.jpg";
import fImg8 from "../../assets/images/fashion/8.jpg";
import fImg9 from "../../assets/images/fashion/9.jpg";
import fImg10 from "../../assets/images/fashion/10.jpg";
import fImg11 from "../../assets/images/fashion/11.jpg";
import fImg12 from "../../assets/images/fashion/12.jpg";
import fImg13 from "../../assets/images/fashion/13.jpg";
import fImg14 from "../../assets/images/fashion/14.jpg";
import fImg15 from "../../assets/images/fashion/15.jpg";
import fImg16 from "../../assets/images/fashion/16.jpg";
import fImg17 from "../../assets/images/fashion/17.jpg";
import fImg18 from "../../assets/images/fashion/18.jpg";
import fImg19 from "../../assets/images/fashion/19.jpg";
import fImg20 from "../../assets/images/fashion/20.jpg";
import fImg21 from "../../assets/images/fashion/21.jpg";
import fImg22 from "../../assets/images/fashion/22.jpg";
import fImg24 from "../../assets/images/fashion/24.jpg";
import fImg25 from "../../assets/images/fashion/25.jpg";
import fImg26 from "../../assets/images/fashion/26.jpg";
import fImg27 from "../../assets/images/fashion/27.jpg";
import fImg28 from "../../assets/images/fashion/28.jpg";
import fImg29 from "../../assets/images/fashion/29.jpg";
import fImg30 from "../../assets/images/fashion/30.jpg";
import fImg31 from "../../assets/images/fashion/31.jpg";
import fImg32 from "../../assets/images/fashion/32.jpg";
import fImg33 from "../../assets/images/fashion/33.jpg";
import fImg34 from "../../assets/images/fashion/34.jpg";
import fImg35 from "../../assets/images/fashion/35.jpg";
import fImg36 from "../../assets/images/fashion/36.jpg";
import fImg37 from "../../assets/images/fashion/37.jpg";
//Import Fashion Images End

//Import Wedding Images Start 
import wImg1 from "../../assets/images/wedding/1.jpg";
import wImg2 from "../../assets/images/wedding/2.jpg";
import wImg3 from "../../assets/images/wedding/3.jpg";
import wImg4 from "../../assets/images/wedding/4.jpg";
import wImg5 from "../../assets/images/wedding/5.jpg";
import wImg6 from "../../assets/images/wedding/6.jpg";
import wImg7 from "../../assets/images/wedding/7.jpg";
import wImg8 from "../../assets/images/wedding/8.jpg";
import wImg9 from "../../assets/images/wedding/9.jpg";
import wImg10 from "../../assets/images/wedding/10.jpg";
import wImg11 from "../../assets/images/wedding/11.jpg";
import wImg12 from "../../assets/images/wedding/12.jpg";
import wImg13 from "../../assets/images/wedding/13.jpg";
import wImg14 from "../../assets/images/wedding/14.jpg";
import wImg15 from "../../assets/images/wedding/15.jpg";
import wImg28 from "../../assets/images/wedding/28.jpg";
import wImg17 from "../../assets/images/wedding/17.jpg";
import wImg18 from "../../assets/images/wedding/18.jpg";
import wImg19 from "../../assets/images/wedding/19.jpg";
import wImg20 from "../../assets/images/wedding/20.jpg";
import wImg21 from "../../assets/images/wedding/21.jpg";
import wImg22 from "../../assets/images/wedding/22.jpg";
import wImg23 from "../../assets/images/wedding/23.jpg";
import wImg24 from "../../assets/images/wedding/24.jpg";
import wImg25 from "../../assets/images/wedding/25.jpg";
import wImg26 from "../../assets/images/wedding/26.jpg";
import wImg27 from "../../assets/images/wedding/27.jpg";
// Import Wedding Images End

//Import Real Estate Images Start 
import rImg1 from "../../assets/images/realestate/1.jpg";
import rImg2 from "../../assets/images/realestate/2.jpg";
import rImg3 from "../../assets/images/realestate/3.jpg";
import rImg4 from "../../assets/images/realestate/4.jpg";
import rImg5 from "../../assets/images/realestate/5.jpg";
import rImg6 from "../../assets/images/realestate/6.jpg";
import rImg7 from "../../assets/images/realestate/7.jpg";
import rImg8 from "../../assets/images/realestate/8.jpg";
import rImg9 from "../../assets/images/realestate/9.png";
import rImg10 from "../../assets/images/realestate/10.png";
import rImg11 from "../../assets/images/realestate/11.png";
//Import Real Estate Images End

// Import Product Images Start 
import pImg1 from "../../assets/images/product/1.jpg";
import pImg2 from "../../assets/images/product/2.jpg";
import pImg3 from "../../assets/images/product/3.jpg";
import pImg4 from "../../assets/images/product/4.jpg";
import pImg5 from "../../assets/images/product/5.jpg";
import pImg6 from "../../assets/images/product/6.jpg";
import pImg7 from "../../assets/images/product/7.jpg";
import pImg8 from "../../assets/images/product/8.jpg";
import pImg11 from "../../assets/images/product/11.jpg";
import pImg12 from "../../assets/images/product/12.jpg";
import pImg14 from "../../assets/images/product/14.jpg";
import pImg15 from "../../assets/images/product/15.jpg";
import pImg16 from "../../assets/images/product/16.jpg";
import pImg17 from "../../assets/images/product/17.jpg";
import pImg18 from "../../assets/images/product/18.jpg";
import pImg19 from "../../assets/images/product/19.jpg";
import pImg20 from "../../assets/images/product/20.jpg";
import pImg21 from "../../assets/images/product/21.jpg";
import pImg22 from "../../assets/images/product/22.jpg";
import pImg23 from "../../assets/images/product/23.jpg";
import pImg24 from "../../assets/images/product/24.jpg";
import pImg25 from "../../assets/images/product/25.jpg";
import pImg26 from "../../assets/images/product/26.jpg";
import pImg27 from "../../assets/images/product/27.jpg";
import pImg28 from "../../assets/images/product/28.jpg";
import pImg30 from "../../assets/images/product/30.jpg";
import pImg31 from "../../assets/images/product/31.jpg";
import pImg32 from "../../assets/images/product/32.jpg";
import pImg33 from "../../assets/images/product/33.jpg";
import pImg34 from "../../assets/images/product/34.jpg";
import pImg35 from "../../assets/images/product/35.jpg";
import pImg36 from "../../assets/images/product/36.jpg";
import pImg37 from "../../assets/images/product/37.jpg";
import pImg38 from "../../assets/images/product/38.jpg";
import pImg39 from "../../assets/images/product/39.jpg";
import pImg40 from "../../assets/images/product/40.jpg";
import pImg41 from "../../assets/images/product/41.jpg";
// Import Product Images End

// Import Food Images Start
import foodImg1 from "../../assets/images/food/1.jpg";
import foodImg2 from "../../assets/images/food/2.jpg";
import foodImg3 from "../../assets/images/food/3.jpg";
import foodImg4 from "../../assets/images/food/4.jpg";
import foodImg5 from "../../assets/images/food/5.jpg";
import foodImg6 from "../../assets/images/food/6.jpg";
import foodImg7 from "../../assets/images/food/7.jpg";
import foodImg8 from "../../assets/images/food/8.jpg";
import foodImg9 from "../../assets/images/food/9.jpg";
import foodImg10 from "../../assets/images/food/10.jpg";
// Import Food Images End

//Import Automotiove Images Start 
import aImg1 from "../../assets/images/automotive/1.jpg";
import aImg2 from "../../assets/images/automotive/2.jpg";
import aImg16 from "../../assets/images/automotive/16.jpg";
import aImg4 from "../../assets/images/automotive/4.jpg";
import aImg5 from "../../assets/images/automotive/5.jpg";
import aImg6 from "../../assets/images/automotive/6.jpg";
import aImg7 from "../../assets/images/automotive/7.jpg";
import aImg8 from "../../assets/images/automotive/8.jpg";
import aImg9 from "../../assets/images/automotive/9.jpg";
import aImg10 from "../../assets/images/automotive/10.jpg";
import aImg13 from "../../assets/images/automotive/13.jpg";
import aImg22 from "../../assets/images/automotive/22.jpg";
//Import Automotive Images End

//Import 360 Degree Images Start
import dImg1 from "../../assets/images/360Degree/1.jpg";
import dImg2 from "../../assets/images/360Degree/2.jpg";
import dImg3 from "../../assets/images/360Degree/3.jpg";
import dImg4 from "../../assets/images/360Degree/4.jpg";
import dImg5 from "../../assets/images/360Degree/5.jpg";
//Import 360 Degree Images End

//Import Timelapse Images Start
import tImg3 from "../../assets/images/timelapse/3.jpg";
import tImg4 from "../../assets/images/timelapse/4.jpg";
import tImg5 from "../../assets/images/timelapse/5.jpg";
import tImg8 from "../../assets/images/timelapse/8.jpg";
import tImg9 from "../../assets/images/timelapse/9.jpg";
import tImg11 from "../../assets/images/timelapse/11.jpg";
import tImg12 from "../../assets/images/timelapse/12.jpg";
import tImg15 from "../../assets/images/timelapse/15.jpg";
import tImg17 from "../../assets/images/timelapse/17.jpg";
import tImg18 from "../../assets/images/timelapse/18.jpg";
import tImg19 from "../../assets/images/timelapse/19.jpg";
import tImg20 from "../../assets/images/timelapse/20.jpg";
import tImg21 from "../../assets/images/timelapse/21.jpg";
import tImg22 from "../../assets/images/timelapse/22.jpg";
import tImg23 from "../../assets/images/timelapse/23.jpg";
import tImg25 from "../../assets/images/timelapse/25.jpg";
import tImg27 from "../../assets/images/timelapse/27.jpg";
import tImg29 from "../../assets/images/timelapse/29.jpg";
import tImg30 from "../../assets/images/timelapse/30.jpg";
import tImg31 from "../../assets/images/timelapse/31.jpg";
import tImg32 from "../../assets/images/timelapse/32.jpg";
//Import Timelapse Images End 

export const settings = {
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: true,
  autoPlay: true,
  autoPlaySpeed: 2000,
  cssEase: "linear",
  keyBoardControl: true,
  partialVisible: true,
  focusOnSelect: true,
  centermode: true,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 481,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
export const testSettings = {
  arrows: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: true,
  autoPlay: true,
  autoPlaySpeed: 2000,
  cssEase: "linear",
  keyBoardControl: true,
  partialVisible: true,
  focusOnSelect: true,
  centermode: true,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 481,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
export const corporateData = [
  {
    id: 1,
    image: cImg1,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 2,
    image: cImg2,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 3,
    image: cImg3,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 4,
    image: cImg4,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 5,
    image: cImg5,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 6,
    image: cImg6,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 7,
    image: cImg7,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 8,
    image: cImg8,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 9,
    image: cImg9,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 10,
    image: cImg10,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 11,
    image: cImg11,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 12,
    image: cImg12,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 13,
    image: cImg13,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 14,
    image: cImg14,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 15,
    image: cImg15,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 16,
    image: cImg16,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 17,
    image: cImg17,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 18,
    image: cImg18,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 19,
    image: cImg19,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 20,
    image: cImg20,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 21,
    image: cImg21,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 22,
    image: cImg22,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 23,
    image: cImg23,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 24,
    image: cImg24,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 25,
    image: cImg25,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 26,
    image: cImg26,
    name: "Corporate Photography in Dubai",
  },
  {
    id: 27,
    image: cImg27,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 28,
    image: cImg28,
    name: "Corporate Videography in Dubai",
  },
  {
    id: 29,
    image: cImg29,
    name: "Corporate Photography in Dubai",
  },
];
export const commercialData = [
  {
    id: 1,
    image: pimg1,
    name: "Carousel Data",
  },
  {
    id: 2,
    image: pimg1,
    name: "C Data",
  },
  {
    id: 2,
    image: pimg1,
    name: "C Data",
  },
  {
    id: 3,
    image: pimg1,
    name: "C Data",
  },
  {
    id: 4,
    image: pimg1,
    name: "C Data",
  },
  {
    id: 5,
    image: pimg1,
    name: "C Data",
  },
  {
    id: 6,
    image: pimg1,
    name: "C Data",
  },
];
export const birthdayData = [
  {
    id: 1,
    image: bImg1,
    name: "Birthday Photography in Dubai",
  },
  {
    id: 2,
    image: bImg2,
    name: "Birthday Photography in Dubai",
  },
  {
    id: 3,
    image: bImg3,
    name: "Birthday Videography in Dubai",
  },
  {
    id: 4,
    image: bImg4,
    name: "Birthday Videography in Dubai",
  },
  {
    id: 5,
    image: bImg5,
    name: "Birthday Photography in Dubai",
  },
  {
    id: 6,
    image: bImg6,
    name: "Birthday Photography in Dubai",
  },
  {
    id: 7,
    image: bImg7,
    name: "Birthday Videography in Dubai",
  },
  {
    id: 8,
    image: bImg8,
    name: "Birthday Videography in Dubai",
  },
  {
    id: 9,
    image: bImg9,
    name: "Birthday Photography in Dubai",
  },
  {
    id: 10,
    image: bImg10,
    name: "Birthday Photography in Dubai",
  },
  {
    id: 11,
    image: bImg11,
    name: "Birthday Videography in Dubai",
  },
  {
    id: 12,
    image: bImg12,
    name: "Birthday Videography in Dubai",
  },
  {
    id: 13,
    image: bImg13,
    name: "Birthday Photography in Dubai",
  },
  
];
export const headshotData = [
  {
    id: 1,
    image: hsImg1,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 2,
    image: hsImg2,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 3,
    image: hsImg3,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 4,
    image: hsImg4,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 5,
    image: hsImg5,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 6,
    image: hsImg6,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 7,
    image: hsImg7,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 8,
    image: hsImg8,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 9,
    image: hsImg9,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 10,
    image: hsImg10,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 11,
    image: hsImg11,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 12,
    image: hsImg12,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 13,
    image: hsImg13,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 14,
    image: hsImg14,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 15,
    image: hsImg15,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 16,
    image: hsImg16,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 17,
    image: hsImg17,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 18,
    image: hsImg18,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 19,
    image: hsImg19,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 20,
    image: hsImg20,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 21,
    image: hsImg21,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 22,
    image: hsImg22,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 23,
    image: hsImg23,
    name: "Headshot Photography in Dubai",
  },
  {
    id: 24,
    image: hsImg24,
    name: "Headshot Photography in Dubai",
  },
];
export const fashionData = [
  {
    id: 1,
    image: fImg1,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 2,
    image: fImg2,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 3,
    image: fImg3,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 4,
    image: fImg4,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 5,
    image: fImg5,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 6,
    image: fImg6,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 7,
    image: fImg7,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 8,
    image: fImg8,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 9,
    image: fImg9,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 10,
    image: fImg10,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 11,
    image: fImg11,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 12,
    image: fImg12,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 13,
    image: fImg13,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 14,
    image: fImg14,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 15,
    image: fImg15,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 16,
    image: fImg16,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 17,
    image: fImg17,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 18,
    image: fImg18,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 19,
    image: fImg19,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 20,
    image: fImg20,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 21,
    image: fImg21,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 22,
    image: fImg22,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 24,
    image: fImg24,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 25,
    image: fImg25,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 26,
    image: fImg26,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 27,
    image: fImg27,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 28,
    image: fImg28,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 29,
    image: fImg29,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 30,
    image: fImg30,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 31,
    image: fImg31,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 32,
    image: fImg32,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 33,
    image: fImg33,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 34,
    image: fImg34,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 35,
    image: fImg35,
    name: "Fashion Photography in Dubai",
  },
  {
    id: 36,
    image: fImg36,
    name: "Fashion Videography in Dubai",
  },
  {
    id: 37,
    image: fImg37,
    name: "Fashion Videography in Dubai",
  },
];
export const weddingData = [
  {
    id: 1,
    image: wImg1,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 2,
    image: wImg2,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 3,
    image: wImg3,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 4,
    image: wImg4,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 5,
    image: wImg5,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 6,
    image: wImg6,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 7,
    image: wImg7,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 8,
    image: wImg8,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 9,
    image: wImg9,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 10,
    image: wImg10,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 11,
    image: wImg11,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 20,
    image: wImg20,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 21,
    image: wImg21,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 22,
    image: wImg22,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 23,
    image: wImg23,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 24,
    image: wImg24,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 25,
    image: wImg25,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 26,
    image: wImg26,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 27,
    image: wImg27,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 28,
    image: wImg28,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 12,
    image: wImg12,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 13,
    image: wImg13,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 14,
    image: wImg14,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 15,
    image: wImg15,
    name: "Wedding Videography in Dubai",
  },
  {
    id: 17,
    image: wImg17,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 18,
    image: wImg18,
    name: "Wedding Photography in Dubai",
  },
  {
    id: 19,
    image: wImg19,
    name: "Wedding Videography in Dubai",
  },
];
export const realEstateData = [
  {
    id: 1,
    image: rImg1,
    name: "real estate Photography in Dubai",
  },
  {
    id: 2,
    image: rImg2,
    name: "real estate Photography in Dubai",
  },
  {
    id: 3,
    image: rImg3,
    name: "real estate Videography in Dubai",
  },
  {
    id: 4,
    image: rImg4,
    name: "real estate Videography in Dubai",
  },
  {
    id: 5,
    image: rImg5,
    name: "real estate Photography in Dubai",
  },
  {
    id: 6,
    image: rImg6,
    name: "real estate Photography in Dubai",
  },
  {
    id: 7,
    image: rImg7,
    name: "real estate Videography in Dubai",
  },
  {
    id: 8,
    image: rImg8,
    name: "real estate Videography in Dubai",
  },
  {
    id: 9,
    image: rImg9,
    name: "real estate Photography in Dubai",
  },
  {
    id: 10,
    image: rImg10,
    name: "real estate Photography in Dubai",
  },
  {
    id: 11,
    image: rImg11,
    name: "real estate Videography in Dubai",
  },
];
export const productData = [
  {
    id: 1,
    image: pImg1,
    name: "product Photography in Dubai",
  },
  {
    id: 2,
    image: pImg2,
    name: "product Photography in Dubai",
  },
  {
    id: 3,
    image: pImg3,
    name: "product Videography in Dubai",
  },
  {
    id: 4,
    image: pImg4,
    name: "product Videography in Dubai",
  },
  {
    id: 5,
    image: pImg5,
    name: "product Photography in Dubai",
  },
  {
    id: 6,
    image: pImg6,
    name: "product Photography in Dubai",
  },
  {
    id: 7,
    image: pImg7,
    name: "product Videography in Dubai",
  },
  {
    id: 8,
    image: pImg8,
    name: "product Videography in Dubai",
  },
  {
    id: 11,
    image: pImg11,
    name: "product Videography in Dubai",
  },
  {
    id: 12,
    image: pImg12,
    name: "product Videography in Dubai",
  },
  {
    id: 14,
    image: pImg14,
    name: "product Photography in Dubai",
  },
  {
    id: 15,
    image: pImg15,
    name: "product Videography in Dubai",
  },
  {
    id: 16,
    image: pImg16,
    name: "product Videography in Dubai",
  },
  {
    id: 17,
    image: pImg17,
    name: "product Photography in Dubai",
  },
  {
    id: 18,
    image: pImg18,
    name: "product Photography in Dubai",
  },
  {
    id: 19,
    image: pImg19,
    name: "product Videography in Dubai",
  },
  {
    id: 20,
    image: pImg20,
    name: "product Videography in Dubai",
  },
  {
    id: 21,
    image: pImg21,
    name: "product Photography in Dubai",
  },
  {
    id: 22,
    image: pImg22,
    name: "product Photography in Dubai",
  },
  {
    id: 23,
    image: pImg23,
    name: "product Videography in Dubai",
  },
  {
    id: 24,
    image: pImg24,
    name: "product Videography in Dubai",
  },
  {
    id: 25,
    image: pImg25,
    name: "product Photography in Dubai",
  },
  {
    id: 26,
    image: pImg26,
    name: "product Photography in Dubai",
  },
  {
    id: 27,
    image: pImg27,
    name: "product Videography in Dubai",
  },
  {
    id: 28,
    image: pImg28,
    name: "product Videography in Dubai",
  },
  {
    id: 30,
    image: pImg30,
    name: "product Photography in Dubai",
  },
  {
    id: 31,
    image: pImg31,
    name: "product Videography in Dubai",
  },
  {
    id: 32,
    image: pImg32,
    name: "product Videography in Dubai",
  },
  {
    id: 33,
    image: pImg33,
    name: "product Photography in Dubai",
  },
  {
    id: 34,
    image: pImg34,
    name: "product Photography in Dubai",
  },
  {
    id: 35,
    image: pImg35,
    name: "product Videography in Dubai",
  },
  {
    id: 36,
    image: pImg36,
    name: "product Videography in Dubai",
  },
  {
    id: 37,
    image: pImg37,
    name: "product Photography in Dubai",
  },
  {
    id: 38,
    image: pImg38,
    name: "product Photography in Dubai",
  },
  {
    id: 39,
    image: pImg39,
    name: "product Videography in Dubai",
  },
  {
    id: 40,
    image: pImg40,
    name: "product Videography in Dubai",
  },
  {
    id: 41,
    image: pImg41,
    name: "product Photography in Dubai",
  },
];
export const foodData = [
  {
    id: 1,
    image: foodImg1,
    name: "food Photography in Dubai",
  },
  {
    id: 2,
    image: foodImg2,
    name: "food Photography in Dubai",
  },
  {
    id: 3,
    image: foodImg3,
    name: "food Videography in Dubai",
  },
  {
    id: 4,
    image: foodImg4,
    name: "food Videography in Dubai",
  },
  {
    id: 5,
    image: foodImg5,
    name: "food Photography in Dubai",
  },
  {
    id: 6,
    image: foodImg6,
    name: "food Photography in Dubai",
  },
  {
    id: 7,
    image: foodImg7,
    name: "food Videography in Dubai",
  },
  {
    id: 8,
    image: foodImg8,
    name: "food Videography in Dubai",
  },
  {
    id: 9,
    image: foodImg9,
    name: "food Photography in Dubai",
  },
  {
    id: 10,
    image: foodImg10,
    name: "food Photography in Dubai",
  },
];
export const automotiveData = [
  {
    id: 1,
    image: aImg1,
    name: "automotive Photography in Dubai",
  },
  {
    id: 2,
    image: aImg2,
    name: "automotive Photography in Dubai",
  },
  {
    id: 4,
    image: aImg4,
    name: "automotive Videography in Dubai",
  },
  {
    id: 5,
    image: aImg5,
    name: "automotive Photography in Dubai",
  },
  {
    id: 6,
    image: aImg6,
    name: "automotive Photography in Dubai",
  },
  {
    id: 7,
    image: aImg7,
    name: "automotive Videography in Dubai",
  },
  {
    id: 8,
    image: aImg8,
    name: "automotive Videography in Dubai",
  },
  {
    id: 9,
    image: aImg9,
    name: "automotive Photography in Dubai",
  },
  {
    id: 10,
    image: aImg10,
    name: "automotive Photography in Dubai",
  },
  {
    id: 13,
    image: aImg13,
    name: "automotive Videography in Dubai",
  },
  {
    id: 22,
    image: aImg22,
    name: "automotive Videography in Dubai",
  },
  {
    id: 16,
    image: aImg16,
    name: "automotive Videography in Dubai",
  },
];
export const degreeData = [
  {
    id: 1,
    image: dImg1,
    name: "360 Degree Photography in Dubai",
  },
  {
    id: 2,
    image: dImg2,
    name: "360 Degree Photography in Dubai",
  },
  {
    id: 3,
    image: dImg3,
    name: "360 Degree Videography in Dubai",
  },
  {
    id: 4,
    image: dImg4,
    name: "360 Degree Videography in Dubai",
  },
  {
    id: 5,
    image: dImg5,
    name: "360 Degree Photography in Dubai",
  },
];
export const timelapseData = [
  {
    id: 3,
    image: tImg3,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 4,
    image: tImg4,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 5,
    image: tImg5,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 8,
    image: tImg8,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 9,
    image: tImg9,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 11,
    image: tImg11,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 12,
    image: tImg12,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 15,
    image: tImg15,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 17,
    image: tImg17,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 18,
    image: tImg18,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 19,
    image: tImg19,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 20,
    image: tImg20,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 21,
    image: tImg21,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 22,
    image: tImg22,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 23,
    image: tImg23,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 25,
    image: tImg25,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 27,
    image: tImg27,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 29,
    image: tImg29,
    name: "Timelapse Photography in Dubai",
  },
  {
    id: 30,
    image: tImg30,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 31,
    image: tImg31,
    name: "Timelapse Videography in Dubai",
  },
  {
    id: 32,
    image: tImg32,
    name: "Timelapse Photography in Dubai",
  },
];