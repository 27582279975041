import React from "react";
import "./pageBannerSection.css"
import { Link } from "react-router-dom";

const PageBannerSection = ({bannerImage, pageTitle }) => {
  return (
    <>
      <section className="banner_section">
        <div className="banner_image">
          <img src={bannerImage} alt="Al Zawiya Film & Production" />
        </div>
        <div className="page_width">
          <div className="banner_section_content">
            <h2>{pageTitle}</h2>
            <h4>
              <span>
                <Link to="/">HOME </Link>
              </span>
              &nbsp; | &nbsp; AL ZAWIYA FILMS & PRODUCTION
            </h4>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageBannerSection;
