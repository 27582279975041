import React from "react";
import { testSettings } from "../../pages/Home/portfoliodata.js";
import Slider from "react-slick";
import TestimonialCard from "./testimonial_card.jsx";
import "./testimonial.css";
import test1 from "../../assets/images/testimonials/test1.jpeg";
import test2 from "../../assets/images/testimonials/test2.jpeg";
import test3 from "../../assets/images/testimonials/test3.jpeg";
import test4 from "../../assets/images/testimonials/test4.jpeg";
import test5 from "../../assets/images/testimonials/test5.jpeg";
import test6 from "../../assets/images/testimonials/test6.jpeg";
import test7 from "../../assets/images/testimonials/test7.jpeg";
import test8 from "../../assets/images/testimonials/test8.jpeg";
import test9 from "../../assets/images/testimonials/test9.jpeg";
import test10 from "../../assets/images/testimonials/test10.jpeg";
import test11 from "../../assets/images/testimonials/test11.jpeg";
import test12 from "../../assets/images/testimonials/test12.jpeg";
import test13 from "../../assets/images/testimonials/test13.jpeg";
import test14 from "../../assets/images/testimonials/test14.jpeg";
import test15 from "../../assets/images/testimonials/test15.jpeg";

const Testimonial = () => {
  return (
    <>
      <section className="google_reviews">
        <div className="page_width">
          <div className="google_reviews_content">
            <div className="section_subtitle">
              <h3>Testimonials</h3>
            </div>
            <div className="section_title">
              <h2>What Our Clients Say?</h2>
            </div>
            <Slider {...testSettings}>
              <TestimonialCard
                testImage={test1}
                testTitle="Maria Kathe"
                testText='" Al Zawiya Film Production brought our corporate event to life with stunning videography and professional editing. Their attention to detail is unmatched. Highly recommended! "'
              />
              <TestimonialCard
                testImage={test2}
                testTitle="Michael T"
                testText='" We hired Al Zawiya for our product photography and the results were beyond our expectations. The images were crisp, clear, and perfectly showcased our products "'
              />
              <TestimonialCard
                testImage={test3}
                testTitle="James K"
                testText='"The 360 video booth from Al Zawiya was the highlight of our wedding. Our guests had a blast, and we have amazing footage to remember the day. "'
              />
              <TestimonialCard
                testImage={test4}
                testTitle="Linda M"
                testText='"The reels produced by Al Zawiya for our social media campaign were incredible. They understood our brand and delivered engaging content that boosted our online presence. "'
              />
              <TestimonialCard
                testImage={test5}
                testTitle="Daniel R"
                testText='" Al Zawiya`s timelapse videos are a work of art. They captured our construction project beautifully, showing progress over months in just a few minutes "'
              />
              <TestimonialCard
                testImage={test6}
                testTitle="Maria Kathe"
                testText='" Working with Al Zawiya for our corporate shoots was a seamless experience. Their team was professional, punctual, and delivered high-quality videos that impressed our stakeholders "'
              />
              <TestimonialCard
                testImage={test7}
                testTitle="Emily R"
                testText='" Al Zawiya Film Production brought our corporate event to life with stunning videography and professional editing. Their attention to detail is unmatched. Highly recommended! "'
              />
              <TestimonialCard
                testImage={test8}
                testTitle="Karen S"
                testText='" The event videography service provided by Al Zawiya captured every important moment of our conference. Their team was discreet and efficient, delivering a final product that exceeded our expectations "'
              />
              <TestimonialCard
                testImage={test9}
                testTitle="Jonathan P"
                testText='" Al Zawiya`s product photography gave our e-commerce site a major upgrade. The photos are clear, well-lit, and make our products look their best. "'
              />
              <TestimonialCard
                testImage={test10}
                testTitle="Oliver H"
                testText='" We are thrilled with the 360 video booth experience from Al Zawiya. It was a unique addition to our event and provided our guests with unforgettable memories. "'
              />
              <TestimonialCard
                testImage={test11}
                testTitle="Mark B"
                testText='" Al Zawiya`s timelapse videos are simply stunning. They have an incredible ability to capture the essence of our projects over time. "'
              />
              <TestimonialCard
                testImage={test12}
                testTitle="Jessica F"
                testText='" The corporate videos produced by Al Zawiya are top-notch. Their creative vision and technical skills are evident in every frame "'
              />
              <TestimonialCard
                testImage={test13}
                testTitle="Laura W"
                testText='" Al Zawiya`s event videography service was outstanding. They captured the spirit of our celebration perfectly, and we couldn’t be happier with the final video. "'
              />
              <TestimonialCard
                testImage={test14}
                testTitle="Sophia L"
                testText='" The product photography from Al Zawiya has taken our marketing materials to the next level. Their attention to detail is remarkable. "'
              />
              <TestimonialCard
                testImage={test15}
                testTitle="David G"
                testText='" We were impressed by Al Zawiya`s ability to produce engaging reels for our social media. Their creativity and professionalism shine through in their work. "'
              />
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
