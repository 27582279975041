import React from 'react'
import { FaGreaterThan } from "react-icons/fa";
import { Link } from 'react-router-dom';
import "./categoryTitleSection.css"

const CategoryTitleSection = ({catTitle}) => {
  return (
    <>
    <section className="categoryTitle">
        <div className="page_width">
            <p><Link to="/">Home</Link> <FaGreaterThan /><FaGreaterThan /> <span>{catTitle}</span></p>
        </div>
    </section>
    </>
  )
}

export default CategoryTitleSection