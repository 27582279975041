import React from "react";
import Layout from "../../Layout/index.jsx";
import PageBannerSection from "../../global/pageBannerSection/pageBannerSection.jsx";
import whatWeDo from "../../assets/images/WhatWeDo.jpeg";
import corporate from "../../assets/images/services/corporate-video-production-dubai.png";
import "./whatWeDo.css";
import { Link } from "react-router-dom";
import timelapseImg from "../../assets/images/services/timelapse.png";
import videoBooth from "../../assets/images/services/360VideoBooth.jpg";
import reelsImg from "../../assets/images/services/reels.jpg";
import realEstateImg from "../../assets/images/services/realEstate.jpg";
import headshotImg from "../../assets/images/services/headshot.jpg";
import productImg from "../../assets/images/services/productPhotography.jpeg";
import fashionImg from "../../assets/images/services/fashionPhotography.jpeg";
import foodImg from "../../assets/images/services/food.jpeg";
import weddingImg from "../../assets/images/services/wedding.jpeg";
import automotiveImg from "../../assets/images/services/automotive.jpeg";
import birthdayImg from "../../assets/images/services/birthday.jpeg";
import ContactSection from "../../global/contactUsSection/ContactSection.jsx";

const WhatWeDo = () => {
  return (
    <>
      <Layout>
        <PageBannerSection
          bannerImage={whatWeDo}
          pageTitle="What We Do"
        ></PageBannerSection>
        <section className="services_section">
          <div className="page_width">
            <div className="services_section_content">
              <h4>Al Zawiya Films & Production</h4>
              <h2>What We Do?</h2>
              <div className="whatWeDo_text">
                <p>
                  Welcome to <Link to="/">Al Zawiya Films & Production</Link>!.
                  Our creative team is dedicated to bringing you some amazing
                  video content on time of your events. We are situated in the
                  vibrant city of Dubai, with a passion to empower your vision
                  into moving and inspiring visuals. No matter what you need a
                  stunning event coverage or a glistening corporate video, or
                  just a visually engaging social media content i-e reels.
                </p>
                <p>
                  We have a wide range of services to meet your any kind of
                  requirements. We do everything from reels, corporate movies,
                  and 360-degree video booths to timelapse recordings. We
                  provides services including professional photography covering
                  headshots, product photos, fashion, and food photography. Our
                  expertise extends to real estate showcases. The scope of our
                  services extends to the conception of dynamic content for the
                  fashion and automobile sectors, along with capturing momentous
                  weddings and birthday events. Here at Al Zawiya Films &
                  Production, we strive hard to give personalized service of the
                  best quality, ensuring that each job goes beyond your
                  expectations. Let's create something amazing together!
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="services_section">
          <div className="page_width">
            <div className="services_section_content">
              <h4>Video Production Agency in Dubai</h4>
              <h2>Our Services</h2>
              <div className="services_content">
                <div className="service1">
                  <Link to="/timelapse">
                    <img
                      src={timelapseImg}
                      alt="Timelapse Videography Services in Dubai"
                    />
                  </Link>
                  <h2>Timelapse</h2>
                </div>
                <div className="service2">
                  <Link to="/360-video-booth">
                    <img
                      src={videoBooth}
                      alt="360 Video Booth Services in Dubai"
                    />
                  </Link>
                  <h2>360 Video Booth</h2>
                </div>
                <div className="service3">
                  <Link to="/reels">
                    <img
                      src={reelsImg}
                      alt="Social Media Reels Shoot Services in Dubai"
                    />
                  </Link>
                  <h2>Reels</h2>
                </div>
                <div className="service4">
                  <Link to="/corporate">
                    <img
                      src={corporate}
                      alt="Corporate Videography Services in Dubai"
                    />
                  </Link>
                  <h2>Corporate</h2>
                </div>
                <div className="service5">
                  <Link to="/real-estate">
                    <img
                      src={realEstateImg}
                      alt="Real Estate Videography Services in Dubai"
                    />
                  </Link>
                  <h2>Real Estate</h2>
                </div>
                <div className="service6">
                  <Link to="/headshot">
                    <img
                      src={headshotImg}
                      alt="Headshot Photography Services in Dubai"
                    />
                  </Link>
                  <h2>Headshot</h2>
                </div>
                <div className="service7">
                  <Link to="/product">
                    <img
                      src={productImg}
                      alt="Product Photography Services in Dubai"
                    />
                  </Link>
                  <h2>Product</h2>
                </div>
                <div className="service8">
                  <Link to="/fashion">
                    <img
                      src={fashionImg}
                      alt="Fashion Photography Services in Dubai"
                    />
                  </Link>
                  <h2>Fashion</h2>
                </div>
                <div className="service9">
                  <Link to="/food">
                    <img
                      src={foodImg}
                      alt="Food Photography Services in Dubai"
                    />
                  </Link>
                  <h2>Food</h2>
                </div>
                <div className="service10">
                  <Link to="/wedding">
                    <img
                      src={weddingImg}
                      alt="Wedding Event Photography Services in Dubai"
                    />
                  </Link>
                  <h2>Wedding</h2>
                </div>
                <div className="service11">
                  <Link to= "/automotive">
                    <img
                      src={automotiveImg}
                      alt="Automotive Photography Services in Dubai"
                    />
                  </Link>
                  <h2>Automotive</h2>
                </div>
                <div className="service12">
                  <Link to="/birthday">
                    <img
                      src={birthdayImg}
                      alt="Birthday Event Photography Services in Dubai"
                    />
                  </Link>
                  <h2>Birthday</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ContactSection></ContactSection>
        <section className="services_section">
          <div className="page_width">
            <div className="services_section_content">
              <div className="services_section_content">
                <h4>Al Zawiya Films & Production</h4>
                <h2>What We Offer?</h2>
              </div>
              <h3>Corporate Events</h3>
              <div className="whatWeDo_text">
                <p>
                  We at Al Zawiya Films & Production, the character, and love of
                  business gatherings are important. Our skilled personnel are
                  good at offering exhaustive reporting that deals with
                  launching products, conferences, work-shops, among other
                  corporate events. In employing current techniques and
                  procedures, we do cutting-edge video production that
                  accurately reflect the quality and dynamism of your business.
                </p>
              </div>
              <h3 className="">Key Services</h3>
              <div className="whatWeDo_text">
                <p>
                  <span>Event Coverage: </span>We provide you complete video
                  coverage of your function that includes interaction shots,
                  speeches, and presentations.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Highlight Reels: </span>We shoot and edit captivating
                  highlight reels that emphasize the high points and most
                  important lessons from your event.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Live Streaming: </span>We also offered live streaming
                  videography services to reach a wider audience and allow
                  real-time participation.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Interviews: </span>We also shoot the on-site interviews
                  with key speakers, attendees, and stakeholders to add depth to
                  your event coverage.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Post Event Videos: </span>Our professional team shoots
                  the post-event videos that can be used for marketing, internal
                  communications, and future promotions.
                </p>
              </div>
              <h3>Corporate Videos</h3>
              <div className="whatWeDo_text">
                <p>
                  <span>Company Profiles: </span>Present your organization's
                  goals, core principles, and achievements in professionally
                  made company profile videos.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Training Videos: </span>Make videos for training staff
                  and new employees which is clear and easy to comprehend by
                  business people.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Internal Communications: </span>Finding creative ways to
                  produce video content for executive communications and team
                  briefs for internal company correspondences.
                </p>
              </div>
              <h3>Timelapse Videos</h3>
              <div className="whatWeDo_text">
                <p>
                  <span>Construction Projects: </span>Create impressive
                  time-lapse videos of construction projects as they start till
                  the end.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Events: </span>Create interesting timelapse highlights
                  by catching the beginning and end of events.
                </p>
              </div>
              <h3>360 Video Booth</h3>
              <div className="whatWeDo_text">
                <p>
                  <span>Interactive Experiences: </span>Create engaging 360º
                  videos for events, tradeshows, and promotions.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Virtual Tours: </span>We create virtual tours of your
                  areas during events, facility inspections, and property
                  viewing.
                </p>
              </div>
              <h3>Social Media Content</h3>
              <div className="whatWeDo_text">
                <p>
                  <span>Reels: </span>We create short videos that are brief,
                  lively, and catered for TikTok, Instagram Reels, and other
                  social media sites.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Campaign Videos: </span>To increase brand visibility and
                  involvement, prepare captivating advertising videos for social
                  media advertisement campaigns.
                </p>
              </div>
              <h3>Real Estate Videos</h3>
              <div className="whatWeDo_text">
                <p>
                  <span>Property Tours: </span>Use attractive and inclusive
                  video tours to highlight your residential and commercial
                  properties.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Aerial Footage: </span>In real estate listings, we use
                  drone photography to add breathtaking aerial photos
                </p>
              </div>
              <h3>Professional Photography</h3>
              <div className="whatWeDo_text">
                <p>
                  <span>Headshots: </span>Provide high-quality LinkedIn,
                  corporation, and business profile headshots.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Product Photography: </span>Increase the sales of your
                  items through complex, attention-catching pictures of
                  products.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Fashion Photography: </span>While doing fashion shoots,
                  we focus on stylish and creative fashion photography that
                  showcases clothes collections and their accessories
                  innovatively.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Food Photography: </span>We create images and
                  advertising videos of mouth-watering dishes and culinary
                  creations for restaurants, cookbooks, and blogs.
                </p>
              </div>
              <h3>Event Videography</h3>
              <div className="whatWeDo_text">
                <p>
                  <span>Weddings: </span>Get captivating wedding films and
                  photographs that capture every memorable moment of your
                  special day.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Birthdays: </span>Create captivating and memorable event
                  videos and photographs that capture your birthdays.
                </p>
              </div>
              <h3>Automotive Videos</h3>
              <div className="whatWeDo_text">
                <p>
                  <span>Car Showcases: </span>Our dynamic videos highlight the
                  features and performance of vehicles.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Promotional Content: </span>Cover promotional events for
                  car launches, dealerships, and automotive events.
                </p>
              </div>
              <h3>Specialized Videography Services</h3>
              <div className="whatWeDo_text">
                <p>
                  <span>Fashion Videos : </span>Produce high-quality videos for
                  fashion shows, designer collections, and marketing videos of
                  designer clothes and accessories.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Food Videos : </span>Create appetizing food videos for
                  restaurants, chefs, and food brands, which increases their
                  sales and brand visibility.
                </p>
              </div>
              <h3>Other Services</h3>
              <div className="whatWeDo_text">
                <p>
                  <span>Music Videos : </span>Generate captivating and creative
                  music videos for musicians and bands.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Documentaries: </span>Tell histories of objects, people,
                  and any incident using in-depth documentaries that resonate
                  well with audiences.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  <span>Animation and Motion Graphics: </span>Generate visually
                  captivating content like animated images or data in a
                  graphical form to help people understand complex subjects more
                  easily.
                </p>
              </div>
              <div className="whatWeDo_text">
                <p>
                  We customize our services to meet your requirements, make sure
                  that your business event is expertly and imaginatively
                  captured on camera. Al Zawiya Films & Production is available
                  to accurately and precisely record any event, no matter how
                  big or small event is.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default WhatWeDo;
