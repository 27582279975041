import React from 'react';
import './client_section.css';
import Clients from "./clients.jsx";
import { settings1, clientsData } from "./clientsdata.js";
import Slider from "react-slick";

const ClientSection = () => {
    const clients_logos = clientsData.map((item) => <Clients img={item.image} />);
  return (
    <>
    <section className="our_clients_section">
        <div className="page_width">
          <div className="our_clients_section_content">
            <h2>Clients We Have Worked For</h2>
            <Slider {...settings1}>{clients_logos}</Slider>
          </div>
        </div>
    </section>
    </>
  )
}

export default ClientSection;