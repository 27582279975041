import img1 from "../../assets/images/blogPost/productPhotographyTips&Tricks.jpeg";
import img2 from "../../assets/images/blogPost/beachPhotographyinDubai.jpeg";
import img3 from "../../assets/images/blogPost/beachWedding.jpeg";
import img4 from "../../assets/images/blogPost/realestateListing.jpeg";
import img5 from "../../assets/images/blogPost/eventPhotography.jpeg";
import img6 from "../../assets/images/blogPost/timelapseVideography.jpeg";
import img7 from "../../assets/images/blogPost/videographerDubai.jpeg";
import img8 from "../../assets/images/blogPost/photographerDubai.jpeg";
import img9 from "../../assets/images/blogPost/corporateEventVideography.jpeg";

export const blogData = [
  {
    id: 1,
    fImage: img1,
    title: "Product Photography Tips & Tricks",
    excert:
      "Admirable product photography is among the core aptitudes that are most wanted in IT corporations, e-commerce, and other online businesses.",
    alt: "Product Photography Tips & Tricks",
    link: "/product-photography-tips-tricks",
  },
  {
    id: 2,
    fImage: img2,
    title: "Beach Photography Tips & Tricks",
    excert:
      "If you have planned a beach photoshoot in Dubai, especially at its beautiful beaches, then here are some top tips from experts to help you plan.",
    alt: "Beach Photoshoot Tips & Tricks",
    link: "/beach-photography-in-dubai",
  },
  {
    id: 3,
    fImage: img3,
    title: "Top Tips for Beach Wedding Photography in Dubai",
    excert:
      "Dubai's stunning coastlines, with their sparkling golden sands and turquoise waters, have set a perfect dream setting for beach weddings. ",
    alt: "Top Tips for Beach Wedding Photography in Dubai",
    link: "/beach-wedding-photography-in-dubai",
  },
  {
    id: 4,
    fImage: img4,
    title: "Essential Tips For Amazing Real Estate Photography Listings",
    excert:
      "Dubai's stunning coastlines, with their sparkling golden sands and turquoise waters, have set a perfect dream setting for beach weddings. ",
    alt: "Essential Tips For Amazing Real Estate Photography Listings",
    link: "/real-estate-photography-in-dubai",
  },
  {
    id: 5,
    fImage: img5,
    title: "How to Plan an Effective Photo Shoot: Concept to Execution",
    excert:
      "Planning a photo session from corporate events to fairytale weddings to colorful, cultural festivals, a lot goes into taking that perfect shot. ",
    alt: "How to Plan an Effective Photo Shoot: Concept to Execution",
    link: "/event-photography-in-dubai",
  },
  {
    id: 6,
    fImage: img6,
    title: "Top Tips for Timelapse Videography in Dubai",
    excert:
      "Dubai vacation is a timelapse videographer dream, with its rocking skyline and mix of ancient culture and, futuristic architecture, backdropped by energy.",
    alt: "Top Tips for Timelapse Videography in Dubai: What's Trending in 2024",
    link: "/timelapse-videography-in-dubai",
  },
  {
    id: 7,
    fImage: img7,
    title: "5 Reasons You Should Hire a Professional Videographer in Dubai For Your Business",
    excert:
      "Dubai vacation is a timelapse videographer dream, with its rocking skyline and mix of ancient culture and, futuristic architecture, backdropped by energy.",
    alt: "5 Reasons You Should Hire a Professional Videographer in Dubai For Your Business",
    link: "/videographer-in-dubai",
  },
  {
    id: 8,
    fImage: img8,
    title: "5 Reasons Why Your Business Needs a Professional Photographer",
    excert:
      "In a world where first impressions are everything, how your business looks online can make or break your brand. Engaging a professional photographer..",
    alt: "5 Reasons Why Your Business Needs a Professional Photographer",
    link: "/photographer-in-dubai",
  },
  {
    id: 9,
    fImage: img9,
    title: "Comprehensive Guide to Corporate Event Videography in Dubai",
    excert:
      "The majority of global corporate events are held in Dubai. From new product launches to business conferences and award ceremonies.",
    alt: "Guide to Corporate Event Videography in Dubai",
    link: "/corporate_videography_in_dubai",
  },
];
