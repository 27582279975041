import React from "react";
import Layout from "../../Layout/index.jsx";
import PageBannerSection from "../../global/pageBannerSection/pageBannerSection.jsx";
import whoWeAre from "../../assets/images/WhatWeAre.jpeg";
import whoWeR from "../../assets/images/who-we-are.png";
import "./whoWeAre.css";
import "../Home/homestyle.css";
import journey from "../../assets/images/our-journey.png";
import philosphy from "../../assets/images/philosphy.png";
import { GoGoal } from "react-icons/go";
import { FaEye } from "react-icons/fa";
import { GiSandsOfTime } from "react-icons/gi";
import { IoMdArrowDropdown } from "react-icons/io";
import { GiBurningPassion } from "react-icons/gi";
import { HiOutlineLightBulb } from "react-icons/hi";
import { MdOutlineConnectWithoutContact } from "react-icons/md";
import { MdDiversity1 } from "react-icons/md";

const WhoWeAre = () => {
  return (
    <>
      <Layout>
        <PageBannerSection
          bannerImage={whoWeAre}
          pageTitle="Who We Are"
        ></PageBannerSection>
        <section className="whoWeAre_text fade-in">
          <div className="page_width">
            <div className="whoWeAre_text_content">
              <div className="whoWeAre_text_content_p">
                <h2 className="">
                  <span>Al Zawiya Films & Production:</span> The Photography and
                  Video Production Company In Dubai
                </h2>
                <p className="">
                  Hi, I am Imran Islam. The CEO of Al Zawiya Films & Production.
                  From the moment, I got my first camera in hand at age 10, to
                  capture the moments. It became a great fascination and passion
                  of mine.
                </p>
                <p className="">
                  We have a 9+ years of experience in food photography, fashion
                  photography, wedding photography, newborn photography,
                  architectural photography, car and automotive photography,
                  illustration to design journalism to product photography and
                  much more. We have found a way to combine real emotion and
                  fact-based knowledge of art into something original. Whether
                  we am photographing landscapes, family portraits or wedding
                  our goal is to create images with emotion.
                </p>
                <p className="">
                  Al Zawiya Films & Production is a team of storytellers who
                  specialize in bringing those narratives to life through
                  wonderful video content. Our team of experts in Dubai combine
                  creativity, technical expertise with in-depth knowledge of
                  current market trends to create compelling films that
                  entertain viewers and meet their expectations.
                </p>
              </div>
              <div className="whoWeAre_text_content_img slide-left">
                <img src={whoWeR} alt="" />
              </div>
            </div>
          </div>
        </section>
        <div className="desktop">
          <section className="whoWeAre_text padd fade-in">
            <div className="page_width">
              <div className="whoWeAre_text_content">
                <div className="whoWeAre_text_content_img slide-right">
                  <img src={journey} alt="" />
                </div>
                <div className="whoWeAre_text_content_p ">
                  <h2 className="">Our Journey</h2>
                  <p className="">
                    Al Zawiya Films & Production founded in 2015. We have
                    emerged as one of the best video production companies based
                    out Dubai since then. We also experienced in social media,
                    marketing, sales, conferences and events as shown in our
                    portfolio.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="mobile">
          <section className="whoWeAre_text padd fade-in">
            <div className="page_width">
              <div className="whoWeAre_text_content">
                <div className="whoWeAre_text_content_p ">
                  <h2 className="">Our Journey</h2>
                  <p className="">
                    Al Zawiya Films & Production founded in 2015. We have
                    emerged as one of the best video production companies based
                    out Dubai since then. We also experienced in social media,
                    marketing, sales, conferences and events as shown in our
                    portfolio.
                  </p>
                </div>
                <div className="whoWeAre_text_content_img slide-right">
                  <img src={journey} alt="Our Journey" />
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="whoWeAre_text padd fade-in">
          <div className="page_width">
            <div className="whoWeAre_text_content">
              <div className="whoWeAre_text_content_p">
                <h2 className="">Our Philosphies</h2>
                <p className="">
                  According to us, video serves as a powerful instrument which
                  can educate, inspire and create change. We always have in mind
                  the primary needs of our customers hence every project is
                  tailored to achieve their specific objectives. Right from the
                  outset, till when the story ends, we engage with you
                  throughout the journey ensuring that your story is told in the
                  most captivating manner possible.
                </p>
              </div>
              <div className="whoWeAre_text_content_img slide-left">
                <img src={philosphy} alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="mission_vision_section">
          <div className="page_width">
            <div className="whoWeAre_text_content">
              <div className="mission_content">
                <h2>Our Mission</h2>
                <div className="mv_content_type">
                  <div className="mv_content_circle01">
                    <div className="mv_content_circle02">
                      <GoGoal />
                    </div>
                  </div>
                  <div className="down_arrow">
                    <IoMdArrowDropdown />
                  </div>
                  <div className="circle">
                    <div className="dotted_line">
                      <p>....</p>
                    </div>
                    <div className="coreValues_content_circle3">
                      <div className="coreValues_content_circle4 colorWhite">
                        <p>&nbsp;</p>
                      </div>
                    </div>
                    <div className="dotted_line">
                      <p>....</p>
                    </div>
                  </div>
                  <div className="value_title">
                    <h3>Mission Statement</h3>
                  </div>
                  <div className="value_context">
                    <p className="p_set">
                      The mission of Al Zawiya Film Production is to create
                      compelling stories for companies that can capture
                      audiences throughout the world by means of high-caliber
                      videos. Located in the heart of Dubai, our goal is to
                      provide outstanding film production services that
                      accurately capture our ever changing environment
                    </p>
                  </div>
                </div>
              </div>
              <div className="vision_content">
                <h2>Our Vision</h2>
                <div className="mv_content_type">
                  <div className="mv_content_circle01">
                    <div className="mv_content_circle02">
                      <FaEye />
                    </div>
                  </div>
                  <div className="down_arrow">
                    <IoMdArrowDropdown />
                  </div>
                  <div className="circle">
                    <div className="dotted_line">
                      <p>....</p>
                    </div>
                    <div className="coreValues_content_circle3">
                      <div className="coreValues_content_circle4 colorWhite">
                        <p>&nbsp;</p>
                      </div>
                    </div>
                    <div className="dotted_line">
                      <p>....</p>
                    </div>
                  </div>
                  <div className="value_title">
                    <h3>Vision Statement</h3>
                  </div>
                  <div className="value_context">
                    <p className="p_set">
                      Our goal is to become the leading video production firm in
                      Dubai, recognized for our creativity, excellence, and
                      proficiency in using video to tell compelling stories.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="coreValues">
          <div className="page_width">
            <div className="coreValues_content">
              <h2>Core Values </h2>
              <div className="coreValues_content_type">
                <div className="coreValues_content_type_detail">
                  <div className="coreValues_content_circle1">
                    <div className="coreValues_content_circle2">
                      <GiSandsOfTime />
                    </div>
                  </div>
                  <div className="down_arrow">
                    <IoMdArrowDropdown />
                  </div>
                  <div className="circle">
                    <div className="dotted_line">
                      <p>....</p>
                    </div>
                    <div className="coreValues_content_circle3">
                      <div className="coreValues_content_circle4">
                        <p>&nbsp;</p>
                      </div>
                    </div>
                    <div className="dotted_line">
                      <p>....</p>
                    </div>
                  </div>
                  <div className="value_title">
                    <h3>INTEGRITY</h3>
                  </div>
                  <div className="value_context">
                    <p className="para">
                      The way we operate our business is based on honesty,
                      transparency and respect.
                    </p>
                  </div>
                </div>
                <div className="coreValues_content_type_detail">
                  <div className="coreValues_content_circle1">
                    <div className="coreValues_content_circle2 borderColor7738">
                      <GiBurningPassion />
                    </div>
                  </div>
                  <div className="down_arrow">
                    <IoMdArrowDropdown />
                  </div>
                  <div className="circle">
                    <div className="dotted_line">
                      <p>....</p>
                    </div>
                    <div className="coreValues_content_circle3">
                      <div className="coreValues_content_circle4 backgroundColor7738">
                        <p>&nbsp;</p>
                      </div>
                    </div>
                    <div className="dotted_line">
                      <p>....</p>
                    </div>
                  </div>
                  <div className="value_title">
                    <h3>PASSION</h3>
                  </div>
                  <div className="value_context">
                    <p className="para">
                      The reason we yet to deliver outstanding work is that we
                      are so enthusiastic.
                    </p>
                  </div>
                </div>
                <div className="coreValues_content_type_detail">
                  <div className="coreValues_content_circle1">
                    <div className="coreValues_content_circle2 borderColor225">
                      <HiOutlineLightBulb />
                    </div>
                  </div>
                  <div className="down_arrow">
                    <IoMdArrowDropdown />
                  </div>
                  <div className="circle">
                    <div className="dotted_line">
                      <p>....</p>
                    </div>
                    <div className="coreValues_content_circle3">
                      <div className="coreValues_content_circle4 backgroundColor225">
                        <p>&nbsp;</p>
                      </div>
                    </div>
                    <div className="dotted_line">
                      <p>....</p>
                    </div>
                  </div>
                  <div className="value_title">
                    <h3>INNOVATION</h3>
                  </div>
                  <div className="value_context">
                    <p className="para">
                      We always try to use innovative ideas with modern
                      technologies.
                    </p>
                  </div>
                </div>
                <div className="coreValues_content_type_detail">
                  <div className="coreValues_content_circle1">
                    <div className="coreValues_content_circle2 borderColor2fe">
                      <MdOutlineConnectWithoutContact />
                    </div>
                  </div>
                  <div className="down_arrow">
                    <IoMdArrowDropdown />
                  </div>
                  <div className="circle">
                    <div className="dotted_line">
                      <p>....</p>
                    </div>
                    <div className="coreValues_content_circle3">
                      <div className="coreValues_content_circle4 backgroundColor2fe">
                        <p>&nbsp;</p>
                      </div>
                    </div>
                    <div className="dotted_line">
                      <p>....</p>
                    </div>
                  </div>
                  <div className="value_title">
                    <h3>COLLABORATION</h3>
                  </div>
                  <div className="value_context">
                    <p className="para">
                      An emphasis placed on teamwork and collaboration, both
                      internally & externally
                    </p>
                  </div>
                </div>
                <div className="coreValues_content_type_detail">
                  <div className="coreValues_content_circle1">
                    <div className="coreValues_content_circle2 borderColorC99">
                      <MdDiversity1 />
                    </div>
                  </div>
                  <div className="down_arrow">
                    <IoMdArrowDropdown />
                  </div>
                  <div className="circle">
                    <div className="dotted_line">
                      <p>....</p>
                    </div>
                    <div className="coreValues_content_circle3">
                      <div className="coreValues_content_circle4 backgroundColorC99">
                        <p>&nbsp;</p>
                      </div>
                    </div>
                    <div className="dotted_line">
                      <p>....</p>
                    </div>
                  </div>
                  <div className="value_title">
                    <h3>DIVERSITY</h3>
                  </div>
                  <div className="value_context">
                    <p className="para">
                      In our work of content creation, we celebrate Dubai's
                      various cultural and viewpoint diversity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default WhoWeAre;
