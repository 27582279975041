import React from "react";
import "./style.css";
import logo from "./logo.png";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="page_width">
          <div className="footer_content">
            <div className="footer1">
              <div className="footer_logo">
                <img src={logo} alt="Al Zawiya Films & Production" />
              </div>
              <nav className=".footer1_navlinks">
                <ul className="footer_navbar">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/why-us">Why Us</Link>
                  </li>
                  <li>
                    <ReactWhatsapp number="+971 555623918" className="whatsapp">
                      Whatsapp
                    </ReactWhatsapp>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="footer2">
              <nav className="footer2_navlinks">
                <ul className="footer2_navbar">
                  <li>
                    <Link to="/get-a-quote">Get A Quote</Link>
                  </li>
                  <li>
                    <a
                      href="https://www.google.com/maps/place/Al+Zawiya+Film's+Production/@25.255863,55.3015792,15z/data=!4m14!1m7!3m6!1s0x3e5f433953808e5d:0x16685017191ea9de!2sAl+Zawiya+Film's+Production!8m2!3d25.255863!4d55.3015792!16s%2Fg%2F11rxhkfq00!3m5!1s0x3e5f433953808e5d:0x16685017191ea9de!8m2!3d25.255863!4d55.3015792!16s%2Fg%2F11rxhkfq00?entry=ttu"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Leave Us a Review
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="social_icons">
                <div className="facebook_icon">
                  <a
                    href="https://www.facebook.com/zawiyafilms/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaFacebookF />
                  </a>
                </div>
                <div className="insta_icon">
                  <a
                    href="https://www.instagram.com/zawiyafilms/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaInstagram />
                  </a>
                </div>
                <div className="vimeo_icon">
                  <FaTiktok />
                </div>
                <div className="linkedin_icon">
                  <a
                    href="https://www.linkedin.com/company/zawiyafilms/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaLinkedinIn />
                  </a>
                </div>
                <div className="youtube_icon">
                  <a
                    href="https://www.youtube.com/@zawiyafilms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaYoutube />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section className="copyright_container">
        <div className="page_width">
          <p className="copyright_text">
            &copy; 2010 - 2024{" "}
            <span className="company_name">
              <a href="/">Zawiya Films & Production</a>
            </span>{" "}
            | All Rights Reserved
          </p>
          <div className="copyright_content">
            <div className="office_address">
              <p>
                Address: Office 206, Al Attar Grand Building, Bank Street,
                Burjuman, Dubai - UAE.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
